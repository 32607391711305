import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpInterceptor, HttpClient, HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpRequestsService } from 'app/services/http-requests.service';
import { catchError, concatMap, delay, filter, retry, retryWhen, switchMap, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs-compat';
import { BehaviorSubject, of, throwError } from 'rxjs';
import 'rxjs/add/operator/catch';
import { TokenStorageService } from './services/token-service';

export const retryCount = 0;
export const retryWaitMilliSeconds = 3000;

const TOKEN_HEADER_KEY = 'Authorization';
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private injector: Injector, private http: HttpClient
    ,private tokenService: TokenStorageService, private httpRequest: HttpRequestsService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    // const authService = this.injector.get(HttpRequestsService)
    let authReq = req;
    const token = this.tokenService.getToken();
    if (token != null) {
      authReq = this.addTokenHeader(req, token);
    }

    return next.handle(authReq).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && !authReq.url.includes('login') && error.status === 401) {
        return this.handle401Error(authReq, next);
      }

      return throwError(error);
    }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const token = this.tokenService.getRefreshToken();

      if (token)
        return this.httpRequest.getRefreshToken(token).pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;

            this.tokenService.saveToken(token.accessToken);
            this.refreshTokenSubject.next(token.accessToken);
            
            return next.handle(this.addTokenHeader(request, token.accessToken));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            
            this.tokenService.signOut();
            return throwError(err);
          })
        );
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    /* for Spring Boot back-end */
    return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });

    /* for Node.js Express back-end */
    // return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, token) });
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true }
];

  // intercept(req, next) {
  //   let authService = this.injector.get(HttpRequestsService)
  //   let tokenizedReq = req.clone({
  //     setHeaders: {
  //       Authorization: `Bearer ${authService.getAccessToken()}`
  //     }
  //   })
  //   return next.handle(tokenizedReq).catch(err => {
  //     console.log(err);
  //     if (err.status === 401) {
  //       let dataToSend = {
  //         "clientId": "GPFocusAngular",
  //         "refreshToken": localStorage.getItem('refresh_token')
  //       }
  //       this.http.post('https://api.bcas.mfit.uk/api/Login/refreshToken',  dataToSend).subscribe((ret:any) => {
  //         console.log(ret);
  //         localStorage.setItem('access_token', ret.accessToken);
  //         localStorage.setItem('refresh_token', ret.refreshToken);
  //         localStorage.setItem('expires_in', ret['.expiresIn']);
  //         let request = req.clone({
  //           setHeaders: {
  //             Authorization: `Bearer ${authService.getAccessToken()}`
  //           }
  //         });
  //         console.log(request);
  //         // window.location.reload();
  //         return next.handle(request).pipe(
  //           retry(this.retryCount)
  //         )
  //       }), (err: any) => {
  //         return err;
  //       }
  //     }
  //   })
  // }
    
  //   return next.handle(tokenizedReq).pipe( tap(() => {},
  //   (err: any) => {
  //   if (err instanceof HttpErrorResponse) {
  //     if (err.status !== 401) {
  //      return;
  //     }
  //     authService.refreshTokenNew();
  //     console.log('error 401');
  //     // this.router.navigate(['login']);
  //   }
  // }));
  
  

