import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpRequestsService } from '../../../services/http-requests.service';
import Swal from 'sweetalert2';
import { State } from '@progress/kendo-data-query';
import { SelectableSettings } from '@progress/kendo-angular-grid';

@Component({
    selector: 'app-assign-job-user',
    templateUrl: './assign-user.component.html',
    styleUrls: ['./assign-user.component.css']
})

export class AssignJobUserComponent {

    selectedRow: any;
    selectedUser: any = [];
    selectedDefaultUser: any = [];
    unselectedUser: any = [];
    selectedBatchJob: any  [];
    // public mySelection: number[] = [2];

    public state: State = {
        skip: 0,
        take: 5,

        // Initial filter descriptor
        // filter: {
        //   logic: 'and',
        //   filters: [{}]
        // }
    };

    public selectableSettings: SelectableSettings = {
        checkboxOnly: true,
        mode: 'single',
        drag: false
      };

      public selectableSettingsBatch: SelectableSettings = {
        checkboxOnly: true,
        mode: 'multiple',
        drag: false
      };

    public value: Date = new Date(Date.now());
    public format = "MM/dd/yyyy HH:mm";

    constructor(
        private sanitizer: DomSanitizer,
        public dialogRef: MatDialogRef<AssignJobUserComponent>,
        protected _httpRequest: HttpRequestsService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {


    }

    ngOnInit(): void {
        console.log(this.data);
        this.selectedDefaultUser.push(this.data.selectedUser);
        this.unselectedUser = this.data.data;
        if(this.selectedDefaultUser.length != 0) {
            this.removeTheDefaultUser();
        }
        console.log(this.selectedDefaultUser);
    }

    removeTheDefaultUser() {
        for(let x = 0; x < this.unselectedUser.length; x++) {
            if(this.data.selectedUser) {
                if(this.unselectedUser[x].id == this.data.selectedUser.id) {
                    this.unselectedUser.splice(x, 1);
                }
            } else {

            }
            
        }
        console.log(this.unselectedUser);
    }

    selectedRowCreate(index, user) {
        this.selectedRow = index;
        this.selectedUser = user;
    }

    assignUser() {
        this._httpRequest.edit('', '/api/job/process/assign/' + this.selectedUser.id + '/' + this.data.jobId).subscribe((ret: any) => {
            this.onNoClick();
        })
        // Swal.fire({
        //     title: 'Would you like to assign this job to the user?',
        //     text: "You won't be able to revert this!",
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonText: 'Yes',
        //     cancelButtonText: 'No, cancel!',
        //     reverseButtons: false
        //   }).then((result) => {
        //     if (result.isConfirmed) {
        //         this._httpRequest.edit('', '/api/job/assign/' + this.selectedUser.Id + '/' + this.data.jobId).subscribe((ret: any) => {
        //             this.onNoClick();

        //             // Swal.fire(
        //             //     'Added!',
        //             //     'Job has been assigned successfuly.',
        //             //     'success'
        //             // ).then((result) => {
        //             //     this.onNoClick();
        //             //     // window.location.reload();
        //             // })
        //         })
        //     } else {

        //     }
        // })
       
    }

    assignUserBatch() {
        let dataToSend: any = [];
        if(this.data.type == 'assign-batch') {
            for (var x = 0 ; x < this.data.jobId.length; x++) {
                dataToSend.push(this.data.jobId[x].id);
            }
        } else if(this.data.type == 'reassign-rebook-batch') {
            for (var x = 0 ; x < this.data.jobId.length; x++) {
                dataToSend.push(this.data.jobId[x].id);
            }
        } else {
            dataToSend = [this.selectedUser.id];
        }
        console.log(dataToSend);
        this._httpRequest.edit(dataToSend, '/api/job/process/batch/assign/' + this.selectedUser.userName + '/').subscribe((ret:any) => {
            console.log(ret);
            if(this.data.type == 'reassign-rebook-batch') {
                this.bookedBatchJobs();
            } else {
                this.onNoClick();
            }
        });
    }

    reassignBatch() {
      let dataToSend: any = [];
      for (var x = 0 ; x < this.data.jobId.length; x++) {
        dataToSend.push(this.data.jobId[x].id);
      }
      this._httpRequest.edit(dataToSend, '/api/job/process/batch/assign/' + this.selectedUser.userName + '/').subscribe((ret:any) => {
        console.log(ret);
        this.onNoClick();
      });
    }

    bookedBatchJobs() {
        let jobs = [];
          for (var x = 0 ; x < this.data.jobId.length; x++) {
            jobs.push(this.data.jobId[x].id);
          }
        let dataToSend = {
          "jobs": jobs,
          "bookedDate": this.formatDateTimeSelected(),
          // "userId": this.selectedUser.id
        }    
          this._httpRequest.edit(dataToSend, '/api/job/process/batch/book').subscribe((ret:any) => {
              // location.reload();
              this.onNoClick();
              // Swal.fire(
              //     'Success!',
              //     'Assigned to user successfully!',
              //     'success'
              // ).then((result) => {
              //     location.reload();
              // })
          });
      }

    reassignAndRebookJobs() {
      let dataToSend = {
        "bookedDate": this.formatDateTimeSelected()
      }
      console.log(dataToSend);
      this._httpRequest.edit('', '/api/job/process/assign/' + this.selectedUser.id + '/' + this.data.jobId).subscribe((ret: any) => {
        this._httpRequest.edit(dataToSend, '/api/job/process/book/' + this.selectedUser.id + '/' + this.data.jobId).subscribe((ret:any) => {
          this.onNoClick();
        });
      })
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    public onSelect(e) {
        this.selectedUser = e.selectedRows[0].dataItem;
        console.log(this.selectedUser);
    }

    onCellClick(e) {
        console.log(e);
    }

    formatDateTimeSelected() {
      let now = this.value;
      let dateTimeformat: any = '';
      let day: any;
      let month: any;
      let time: any;
      if((now.getMonth() + 1) == 1 ||(now.getMonth() + 1) == 2 ||(now.getMonth() + 1) == 3 ||(now.getMonth() + 1) == 4 ||(now.getMonth() + 1) == 5 ||(now.getMonth() + 1) == 6 ||(now.getMonth() + 1) == 7 ||(now.getMonth() + 1) == 8 ||(now.getMonth() + 1) == 9) {
        month = "0" + (now.getMonth() + 1);
      } else {
        month = now.getMonth() + 1;
      }
    
      if(now.getDate() == 1 ||now.getDate() == 2 ||now.getDate() == 3 ||now.getDate() == 4 ||now.getDate() == 5 ||now.getDate() == 6 ||now.getDate() == 7 ||now.getDate() == 8 ||now.getDate() == 9){
        day = "0" + now.getDate();
      } else {
        day = now.getDate();
      }
    
      if((now.getSeconds() == 0 || now.getSeconds() == 1 || now.getSeconds() == 2 || now.getSeconds() == 3 || now.getSeconds() == 4 || now.getSeconds() == 5 || now.getSeconds() == 6 || now.getSeconds() == 7 || now.getSeconds() == 8 || now.getSeconds() == 9) && (now.getMinutes() == 0 || now.getMinutes() == 1 || now.getMinutes() == 2 || now.getMinutes() == 3 || now.getMinutes() == 4 || now.getMinutes() == 5 || now.getMinutes() == 6 || now.getMinutes() == 7 || now.getMinutes() == 8 || now.getMinutes() == 9) && (now.getHours() == 0 || now.getHours() == 1 || now.getHours() == 2 || now.getHours() == 3 || now.getHours() == 4 || now.getHours() == 5 || now.getHours() == 6 || now.getHours() == 7 || now.getHours() == 8 || now.getHours() == 9)) {
        time = "T" + "0" + now.getHours() + ":" + "0" + now.getMinutes() + ":" + "0" + now.getSeconds();
      } else if((now.getSeconds() == 0 || now.getSeconds() == 1 || now.getSeconds() == 2 || now.getSeconds() == 3 || now.getSeconds() == 4 || now.getSeconds() == 5 || now.getSeconds() == 6 || now.getSeconds() == 7 || now.getSeconds() == 8 || now.getSeconds() == 9) && (now.getMinutes() == 0 || now.getMinutes() == 1 || now.getMinutes() == 2 || now.getMinutes() == 3 || now.getMinutes() == 4 || now.getMinutes() == 5 || now.getMinutes() == 6 || now.getMinutes() == 7 || now.getMinutes() == 8 || now.getMinutes() == 9)) {
        time = "T" + now.getHours() + ":" + "0" + now.getMinutes() + ":" + "0" + now.getSeconds();
      } else if((now.getSeconds() == 0 || now.getSeconds() == 1 || now.getSeconds() == 2 || now.getSeconds() == 3 || now.getSeconds() == 4 || now.getSeconds() == 5 || now.getSeconds() == 6 || now.getSeconds() == 7 || now.getSeconds() == 8 || now.getSeconds() == 9) && (now.getHours() == 0 || now.getHours() == 1 || now.getHours() == 2 || now.getHours() == 3 || now.getHours() == 4 || now.getHours() == 5 || now.getHours() == 6 || now.getHours() == 7 || now.getHours() == 8 || now.getHours() == 9)) {
        time = "T" + "0" + now.getHours() + ":" + now.getMinutes() + ":" + "0" + now.getSeconds();
      } else if((now.getMinutes() == 0 || now.getMinutes() == 1 || now.getMinutes() == 2 || now.getMinutes() == 3 || now.getMinutes() == 4 || now.getMinutes() == 5 || now.getMinutes() == 6 || now.getMinutes() == 7 || now.getMinutes() == 8 || now.getMinutes() == 9) && (now.getHours() == 0 || now.getHours() == 1 || now.getHours() == 2 || now.getHours() == 3 || now.getHours() == 4 || now.getHours() == 5 || now.getHours() == 6 || now.getHours() == 7 || now.getHours() == 8 || now.getHours() == 9)) {
        time = "T" + "0" + now.getHours() + ":" + "0" + now.getMinutes() + ":" + now.getSeconds();
      } else if(now.getSeconds() == 0 || now.getSeconds() == 1 || now.getSeconds() == 2 || now.getSeconds() == 3 || now.getSeconds() == 4 || now.getSeconds() == 5 || now.getSeconds() == 6 || now.getSeconds() == 7 || now.getSeconds() == 8 || now.getSeconds() == 9) {
        time = "T" + now.getHours() + ":" + now.getMinutes() + ":" + "0" + now.getSeconds();
      } else if(now.getMinutes() == 0 || now.getMinutes() == 1 || now.getMinutes() == 2 || now.getMinutes() == 3 || now.getMinutes() == 4 || now.getMinutes() == 5 || now.getMinutes() == 6 || now.getMinutes() == 7 || now.getMinutes() == 8 || now.getMinutes() == 9) {
        time = "T" + now.getHours() + ":" + "0" + now.getMinutes() + ":" + now.getSeconds();
      } else if(now.getHours() == 0 || now.getHours() == 1 || now.getHours() == 2 || now.getHours() == 3 || now.getHours() == 4 || now.getHours() == 5 || now.getHours() == 6 || now.getHours() == 7 || now.getHours() == 8 || now.getHours() == 9) {
        time = "T" + "0"  + now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds();
      } else {
        time = "T" + now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds()
      }
    
      dateTimeformat = now.getFullYear() + "-" + month + "-" + day + time;
      // console.log(dateTimeformat);
      return dateTimeformat
    }

      
}