import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation, Inject, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { GridComponent } from '@progress/kendo-angular-grid';
import { exportPDF, Group } from '@progress/kendo-drawing';
import { HttpRequestsService } from 'app/services/http-requests.service';
import { saveAs } from "@progress/kendo-file-saver";
import { AddJobDetailsDialogComponent } from '../add-job-details/add-job-details.component';

@Component({
  selector: 'app-view-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.css']
})

export class ViewJobDetailsDialogComponent {
  @ViewChildren(GridComponent)
  public grids: QueryList<GridComponent>;

  jobStatusHistory:any = [];
  jobStatusNotes:any = [];
  jobStatusAttachments: any = [];
  jobStatusLinkedJobs: any = [];
  job:any = [];
  base64Image = [];
  hide = true;
  tabPage = 0;
  loading = true;
  constructor(
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<ViewJobDetailsDialogComponent>,
    private http: HttpClient,
    private _httpRequest: HttpRequestsService,
    public dialog: MatDialog,
    private ref: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    
  }

  ngOnInit(): void {
    this.job.push(this.data);
    console.log(this.job);
    this.getFullHistory(this.data);
    this.getNotes(this.data);
    this.getAttachments(this.data);
    this.getCustomerFeedback(this.job[0]);
    // this.jobStatusHistory = this.data.history;
    // this.jobStatusNotes = this.data.note;
    // this.jobStatusAttachments = this.data.attachments;
    // this.jobStatusLinkedJobs = this.data.linkedJobs;
    // console.log(this.jobStatusAttachments);
    // this.getBase64()
  }

  getCustomerFeedback(data) {
    this._httpRequest.get('/api/customerfeedback/answers/' + data.id).subscribe((ret: any) => {
      console.log(ret);
    })
  }

  getBase64() {
    console.log(this.jobStatusHistory.length)
    for(let x = 0; x < this.jobStatusHistory.length; x++) {
      if(this.jobStatusHistory[x].JobStatusHistorySignature !== null) {
        console.log(x);
        this.base64Image[x] = this.jobStatusHistory[x].JobStatusHistorySignature.Signature.File;
      }
    }
    console.log(this.base64Image);
  }

  onNoClick(): void {
    this.dialogRef.close('Cancel');
  }

  public getSantizeUrl(url : string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  print(elem) {
    // window.print();
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');

    mywindow.document.write('<html><head><title>' + document.title  + '</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write('<h1>' + document.title  + '</h1>');
    mywindow.document.write(document.getElementById(elem).innerHTML);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();


    return true;
  }

  getExcel(data){
    let sUrl:string = "https://api.bcas.mfit.uk/" + data.url;
    let headers = new HttpHeaders({
      'Authorization':'Bearer ' + localStorage.getItem('access_token')
    })
    return this.http.get(sUrl, { headers:headers, responseType:"blob"});
  }

  exportAttachment(attachment) {
    console.log(attachment);
    this.getExcel(attachment).subscribe(data => this.downloadFile(data, attachment.type, attachment)),
        error => console.log("Error downloading the file."),
        () => console.log("Error");
  }

  downloadFile(data: any, type, fileData){
    var url = window.URL.createObjectURL(new Blob([data]));
    var a = document.createElement('a');
    console.log(a);
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = fileData.name;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  getFullHistory(job) {
    this._httpRequest.get('/api/job/status/history/full/' + job.id).subscribe((ret: any) => {
      console.log(ret);
      this.jobStatusHistory = ret;
    })
  }

  getNotes(job) {
    this._httpRequest.get('/api/job/note/all/' + job.id).subscribe((ret: any) => {
      this.jobStatusNotes = ret;
    })
  }

  getAttachments(job) {
    console.log(job)
      this._httpRequest.get('/api/job/attachment/file/' + job.id).subscribe((ret: any) => {
        this.jobStatusAttachments = ret;
        this.loading = false;
      })
  }

  public exportGrids(): void {
    const promises = this.grids.map((grid) => grid.drawPDF());
    Promise.all(promises)
      .then((groups) => {
        const rootGroup = new Group({
          pdf: {
            multiPage: true,
          },
        });
        groups.forEach((group) => {
          rootGroup.append(...group.children);
        });

        return exportPDF(rootGroup, { paperSize: "A4", landscape: true });
      })
      .then((dataUri) => {
        let name: any;
        if(this.job[0].equipment && this.job[0].equipment) {
          name = this.job[0].equipment.assetNumber + '-' + this.job[0].equipment.site.address1 + '-history';
        } else {
          name = 'Job Details.pdf'
        }
        saveAs(dataUri, name + '.pdf');
      });
  }

  setTabView(event) {
    console.log(event)
    this.tabPage = event.index;
  }

  openDialogaddJob(type): void {
    this.ref.detach();
    console.log('aw');
    let dataToPass: any;
    if(type == 'note') {
      dataToPass = {
        type: type,
        jobDetails: this.job[0]
      }
      
    } else if(type == 'attachment') {
      dataToPass = {
        type: type,
        jobDetails: this.job[0]
      }
    }
    const dialogRef = this.dialog.open(AddJobDetailsDialogComponent, {
      width: '100em',
      height: '50em',
      data: dataToPass
      // data: { questions: this.addForm.value, sectionIndex: index }
    });

    dialogRef.afterClosed().subscribe(result => {
        this.ref.reattach();
        console.log(result);
        this.getNotes(this.data);
        this.getAttachments(this.data);
    });
    
  }
}