import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { HttpRequestsService } from './http-requests.service';

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(
        protected _router: Router,
        protected _httpRequest: HttpRequestsService
    ) {}

    canActivate() {
        if (this._httpRequest.isLogin()) {
        return true;
        }
        this._router.navigate(['login']);
        return false;
    }
}
