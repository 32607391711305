import { BookedJobDialogComponent } from './../../layouts/jobs/booked-job/booked-job.component';
import { ViewJobDetailsDialogComponent } from './../../layouts/jobs/job-details/job-details.component';
import { AssignJobUserComponent } from './../../layouts/jobs/assign-user/assign-user.component';
import { Component, OnInit, ElementRef, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';

import { HttpRequestsService } from 'app/services/http-requests.service';
import { ConnectionService } from 'ng-connection-service';
import { MatStepper } from '@angular/material/stepper/stepper';

import Swal from 'sweetalert2';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { GridDataResult, DataStateChangeEvent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { process, State } from '@progress/kendo-data-query';

// import { ViewJobDetailsDialogComponent } from '../../layouts/jobs/p';
import { JobSurveyPreviewDialogComponent } from '../../layouts/jobs/preview/preview.component';
import { MatDialog } from '@angular/material/dialog';
import { AddJobDetailsDialogComponent } from 'app/layouts/jobs/add-job-details/add-job-details.component';
// import { AddJobDetailsDialogComponent } from './add-job-details/add-job-details.component';

import { FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { DataService } from './../../services/request-kendo.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';



@Component({
    selector: 'app-job-data-grid',
    templateUrl: './jobs-data-grid.component.html',
    styleUrls: ['./jobs-data-grid.component.scss']
})


export class JobsDataGridComponent implements OnInit {

    @Input() public textField: string;
    @Input() public valueField: string;

    @Input() data: any;
    allJobs: any;
    unassignedJobs: any;
    assignedJobs: any;
    inProgressJobs: any;
    pausedJobs: any;
    completedJobs: any;
    failedJobs: any;
    pendingJobs: any;
    bookedJobs: any;
    public buttonCount = 5;
    public info = true;
    public pageSizes = [5, 10, 25, 100];
    public previousNext = true;

    public pageSize = 1000000;
    public skip = 0;
    public gridData: any = [];
    mappedData: any = [];
    public gridView: GridDataResult;
    public gridViewUnassignedJobs: GridDataResult;
    public gridViewAssignedJobs: GridDataResult;
    public gridViewInProgressJobs: GridDataResult;
    public gridViewBookedJobs: GridDataResult;
    public gridViewPausedJobs: GridDataResult;
    public gridViewFailedJobs: GridDataResult;
    public gridViewPendingJobs: GridDataResult;
    public gridViewCompletedJobs: GridDataResult;

    public multiple = false;
    public allowUnsort = true;
    public sort: SortDescriptor[] = [
        {
            field: 'ProductName',
            dir: 'asc'
        }
    ];


   

    public categories = [];

    public state: State = {
        skip: 0,
        take: 5,

        // Initial filter descriptor
        filter: {
          logic: 'and',
          filters: []
        }
    };

    // Datetime variables
    d: any;
    dateToday: any;
    twoWeeksDate: any;
    twoWeeks: any;
    sixWeeksDate: any;
    sixWeeks: any;
    // categories = JSON.parse(localStorage.getItem('JobStatus'));

    showClearFilterButton = false;
    actionList:Array<string> = ['', 'View Detail', 'View Results', 'Assign to User', 'Add Note', 'Add Attachment', 'Download Excel', 'Download PDF'];
    actionListUnassigned:Array<string> = ['', 'View Detail', 'Assign Job', 'Assign and Book', 'Add Note', 'Add Attachment'];
    actionListAssigned:Array<string> = ['', 'View Detail', 'Reassign Job', 'Book Job', 'Reassign and Book Job', 'Add Note', 'Add Attachment'];
    actionListBookedJobs:Array<string> = ['', 'View Detail', 'Reassign Job', 'Reassign and Rebook Job', 'Rebook Job', 'Add Note', 'Add Attachment']
    actionListInProgress:Array<string> = ['', 'View Detail', 'Add Note', 'Add Attachment'];
    actionListPaused:Array<string> = ['', 'View Detail', 'Assign to User', 'Add Note', 'Add Attachment'];
    actionListFailed:Array<string> = ['', 'View Detail', 'View Results', 'Add Note', 'Add Attachment', 'Download Excel', 'Download PDF'];
    actionListPending:Array<string> = ['', 'View Detail', 'Assign to User', 'Add Note', 'Add Attachment', 'Book Job'];
    actionListCompleted:Array<string> = ['', 'View Detail', 'View Results', 'Add Note', 'Add Attachment', 'Download Excel', 'Download PDF'];

    actionListNoDetailsAndDownload:Array<string> = ['', 'View Results', 'Add Note', 'Add Attachment'];
    actionListNoDetailsAndDownloadNoUser:Array<string> = ['', 'View Results', 'Assign to User', 'Add Note', 'Add Attachment'];
    actionListValue = 'Select Action...';
    unAssignedJobsBatchActionDefault = 'Batch Actions...';
    unAssignedJobsBatchAction:Array<string> = ['', 'Assign Batch Jobs', 'Assign and Book Batch Jobs'];
    assignedJobsBatchAction:Array<string> = ['', 'Reassign Batch Jobs','Book Batch Jobs', 'Reassign and Book Batch Jobs'];
    bookedJobsBatchAction:Array<string> = ['', 'Reassign Batch Jobs','Rebook Batch Jobs', 'Reassign and Rebook Batch Jobs',];
    // assignedJobsAction:Array<string> = ['', 'Book Job'];


    showTabTable = '';
    tableShowIndex = 0;

    selectedUnassignedJobs: any = [];
    selectedAssignedJobs: any = [];
    showBatchAction: boolean;
    showBatchActionAssigned: boolean;
    showBatchActionBooked: boolean;
    selectedBookedJobs: any = [];

    currentUrl: string = '';

    constructor(
        location: Location,
        private element: ElementRef,
        private router: Router,
        private _httpRequest: HttpRequestsService,
        private connectionService: ConnectionService,
        private ngxLoader: NgxUiLoaderService,
        public dialog: MatDialog,
        private http: HttpClient,
        private ref: ChangeDetectorRef
    ) {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        let dateToday = yyyy + '/' + dd + '/' + mm;
        this.getDateTime();
        this.currentUrl = router.url;
    }

    ngOnInit() {
        console.log('joooooooooobs',this.data);
        this.showTabTable = this.router.url.replace('/dashboard/jobs/', '');
        console.log(this.showTabTable);
        this.checkTableTabToOpen();
        this.mappedData = this.data[0].allJobs.map(item => {
            item.DueDate = new Date(item.DueDate);
            return item;
          });
          this.mappedData = this.data[0].allJobs.map(item => {
            item.DateCreated = new Date(item.DateCreated);
            return item;
          });
          console.log(this.mappedData);
        this.allJobs = this.data[0].allJobs;
        this.unassignedJobs = this.data[0].unassignedJobs;
        this.assignedJobs = this.data[0].assignedJobs;
        this.inProgressJobs = this.data[0].inProgressJobs;
        this.pausedJobs = this.data[0].pausedJobs;
        this.failedJobs = this.data[0].failJobs;
        this.pendingJobs = this.data[0].pendingJobs;
        this.completedJobs = this.data[0].completedJobs;
        this.bookedJobs = this.data[0].bookedJobs;
        this.pendingJobs = this.data[0].pendingJobs;
        this.gridData = this.data[0].allJobs;
        this.loadProducts();
        this.loadMore();
        this.processJobStatus();
        console.log(this.data);
    }

    public clearFilters(state: DataStateChangeEvent) {
        this.categories = [];
        this.processJobStatus();
        this.state.filter = {
          logic: 'and',
          filters: []
        };
        
        this.dataStateChange(state, 'all');
        this.actionListValue = 'Select Action...';
        this.showClearFilterButton = false;
    }

    checkTableTabToOpen() {
        if(this.showTabTable == 'all-jobs') {
            this.tableShowIndex = 0;
        } else if(this.showTabTable == 'unassigned-jobs') {
            this.tableShowIndex = 1;
        } else if(this.showTabTable == 'assigned-jobs') {
            this.tableShowIndex = 2;
        } else if(this.showTabTable == 'in-progress-jobs') {
            this.tableShowIndex = 4;
        } else if(this.showTabTable == 'paused-jobs') {
            this.tableShowIndex = 5;
        } else if(this.showTabTable == 'completed-jobs') {
            this.tableShowIndex = 8;
        } else if(this.showTabTable == 'booked-jobs') {
            this.tableShowIndex = 3;
        } else if(this.showTabTable == 'pending-review') {
            this.tableShowIndex = 6;
        } else if(this.showTabTable == 'fail-jobs') {
            this.tableShowIndex = 7;
        } else {
            this.tableShowIndex = Number(localStorage.getItem('tableShowIndex'));
        }
    }

    processJobStatus() {
        let status =  JSON.parse(localStorage.getItem('JobStatus'));
        console.log(status);
        if(status){
            for(let x = 0; x < status.length; x++) {
                this.categories.push(status[x].NiceName);
            }
        }
        else {
            this._httpRequest.get('/api/job/status').subscribe((ret: any) => {
                for(let x = 0; x < ret.length; x++) {
                    this.categories.push(ret[x].niceName);
                }
                console.log(this.categories);
            })
        }
    }

    getDateTime() {
        if (localStorage.getItem('DateToday') && localStorage.getItem('TwoWeeks') && localStorage.getItem('SixWeeks')) {
            this.dateToday = JSON.parse(localStorage.getItem('DateToday'));
            this.twoWeeks = JSON.parse(localStorage.getItem('TwoWeeks'));
            this.sixWeeks = JSON.parse(localStorage.getItem('SixWeeks'));
        } else {
            this.d = new Date();
            this.twoWeeksDate = new Date(+new Date + 12096e5);
            this.sixWeeksDate = new Date(+new Date + 36288e5);
            this.dateToday = this.d.getFullYear() + '-' + this.pad((this.d.getMonth() + 1)) + '-' + this.d.getDate();
            this.twoWeeks = this.twoWeeksDate.getFullYear() + '-' + this.pad((this.twoWeeksDate.getMonth() + 1)) + '-' + this.twoWeeksDate.getDate();
            this.sixWeeks = this.sixWeeksDate.getFullYear() + '-' + this.pad((this.sixWeeksDate.getMonth() + 1)) + '-' + this.sixWeeksDate.getDate();
            localStorage.setItem('DateToday', JSON.stringify(this.d.getFullYear() + '-' + this.pad((this.d.getMonth() + 1)) + '-' + this.d.getDate()));
            localStorage.setItem('TwoWeeks', JSON.stringify(this.twoWeeksDate.getFullYear() + '-' + this.pad((this.twoWeeksDate.getMonth() + 1)) + '-' + this.twoWeeksDate.getDate()));
            localStorage.setItem('SixWeeks', JSON.stringify(this.sixWeeksDate.getFullYear() + '-' + this.pad((this.sixWeeksDate.getMonth() + 1)) + '-' + this.sixWeeksDate.getDate()));
        }
    }

    pad(d) {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }

    protected pageChange({ skip, take }: PageChangeEvent): void {
        this.skip = skip;
        this.pageSize = take;
        this.loadProducts();
    }

    private loadProducts(): void {
        this.gridView = {
            data: this.allJobs.slice(this.skip, this.skip + this.pageSize),
            total: this.allJobs.length
        };
        this.gridViewUnassignedJobs = {
            data: this.unassignedJobs.slice(this.skip, this.skip + this.pageSize),
            total: this.unassignedJobs.length
        }
        this.gridViewAssignedJobs = {
            data: this.assignedJobs.slice(this.skip, this.skip + this.pageSize),
            total: this.assignedJobs.length
        }

        this.gridViewInProgressJobs = {
            data: this.inProgressJobs.slice(this.skip, this.skip + this.pageSize),
            total: this.inProgressJobs.length
        }

        this.gridViewBookedJobs = {
            data: this.bookedJobs.slice(this.skip, this.skip + this.pageSize),
            total: this.bookedJobs.length
        }

        this.gridViewPausedJobs = {
            data: this.pausedJobs.slice(this.skip, this.skip + this.pageSize),
            total: this.pausedJobs.length
        }

        this.gridViewFailedJobs = {
            data: this.failedJobs.slice(this.skip, this.skip + this.pageSize),
            total: this.failedJobs.length
        }
        
        this.gridViewPendingJobs = {
            data: this.pendingJobs.slice(this.skip, this.skip + this.pageSize),
            total: this.pendingJobs.length
        }

        this.gridViewCompletedJobs = {
            data: this.completedJobs.slice(this.skip, this.skip + this.pageSize),
            total: this.completedJobs.length
        }
    }

    public sortChange(sort: SortDescriptor[]): void {
        console.log(sort);
        this.sort = sort;
        this.sortProducts();
    }

    private sortProducts(): void {
        this.gridView = {
            data: orderBy(this.gridData, this.sort).slice(this.skip, this.skip + this.pageSize),
            total: this.gridData.length
        };
    }

    public dataStateChange(state: DataStateChangeEvent, gridName): void {
        console.log(JSON.stringify(state));
        this.showClearFilterButton = true;
        console.log(typeof state);
        if(typeof state == "string") {
            console.log('yes')
            this.state = {
                "filter": {
                    "filters": [{
                        "field": "Status.NiceName",
                        "operator": "contains",
                        "value": state
                    }],
                    "logic": "and"
                },
            }
            if(gridName == 'allJobs') {
                this.gridView = process(this.gridData, this.state);
            } else if(gridName == 'unassignedJobs') {
                this.gridViewUnassignedJobs = process(this.unassignedJobs, this.state)
            } else if(gridName == 'assignedJobs') {
                this.gridViewAssignedJobs = process(this.assignedJobs, this.state)
            } else if(gridName == 'bookedJobs') {
                this.gridViewBookedJobs = process(this.bookedJobs, this.state)
            } else if(gridName == 'inProgressJobs') {
                this.gridViewInProgressJobs = process(this.inProgressJobs, this.state)
            } else if(gridName == 'pausedJobs') {
                this.gridViewPausedJobs = process(this.pausedJobs, this.state)
            } else if(gridName == 'pendingJobs') {
                this.gridViewPendingJobs = process(this.pendingJobs, this.state)
            } else if(gridName == 'failedJobs') {
                this.gridViewFailedJobs = process(this.failedJobs, this.state)
            } else if(gridName == 'completedJobs') {
                this.gridViewCompletedJobs = process(this.completedJobs, this.state)
            }
        } else {
            this.state = state;
            if(gridName == 'allJobs') {
                this.state = state;
                this.gridView = process(this.gridData, this.state);
            } else if(gridName == 'unassignedJobs') {
                this.gridViewUnassignedJobs = process(this.unassignedJobs, this.state)
            } else if(gridName == 'assignedJobs') {
                this.gridViewAssignedJobs = process(this.assignedJobs, this.state)
            } else if(gridName == 'bookedJobs') {
                this.gridViewBookedJobs = process(this.bookedJobs, this.state)
            } else if(gridName == 'inProgressJobs') {
                this.gridViewInProgressJobs = process(this.inProgressJobs, this.state)
            } else if(gridName == 'pausedJobs') {
                this.gridViewPausedJobs = process(this.pausedJobs, this.state)
            } else if(gridName == 'pendingJobs') {
                this.gridViewPendingJobs = process(this.pendingJobs, this.state)
            } else if(gridName == 'failedJobs') {
                this.gridViewFailedJobs = process(this.failedJobs, this.state)
            } else if(gridName == 'completedJobs') {
                this.gridViewCompletedJobs = process(this.completedJobs, this.state)
            }
            
        }
    }

    openDialogPreview(data): void {
        console.log(data)
        let dataToPass = {
            data: '',
            answers: ''
        };
        this._httpRequest.get('/api/Survey/' + data.surveyId).subscribe((ret: any) => {
            console.log(ret);
            dataToPass.data = ret.sections
            this._httpRequest.get('/api/Answer/job/' + data.id).subscribe((ret: any) => {
                console.log(ret);
                dataToPass.answers = ret;
                    const dialogRef = this.dialog.open(JobSurveyPreviewDialogComponent, {
                        width: '100em',
                        height: '50em',
                        data: { questions: dataToPass, data:data }
                        // data: { questions: this.addForm.value, sectionIndex: index }
                    });
    
                    dialogRef.afterClosed().subscribe(result => {
                        console.log(result);
                        console.log(this.currentUrl);
                        this.ngOnInit();
                        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                            let redirectTo = this.currentUrl;
                            this.router.navigate([redirectTo]);
                        });
                    });
                // if (ret.length == 0) {
                //     Swal.fire("Error", "This Job has not been answered yet", "warning").then((result) => {
    
                //     })
                // } else {
                //     const dialogRef = this.dialog.open(JobSurveyPreviewDialogComponent, {
                //         width: '100em',
                //         height: '50em',
                //         data: { questions: dataToPass }
                //         // data: { questions: this.addForm.value, sectionIndex: index }
                //     });
    
                //     dialogRef.afterClosed().subscribe(result => {
                //         console.log(result);
                //         console.log(this.currentUrl);
                //         this.ngOnInit();
                //         this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                //             let redirectTo = this.currentUrl;
                //             this.router.navigate([redirectTo]);
                //         });
                //     });
                // }
            })
        })
    }

    openDialogaddJob(type, data): void {
        console.log('aw');
        let dataToPass = {
            type: type,
            jobDetails: data
        }
        
        this.ref.detach();
        const dialogRef = this.dialog.open(AddJobDetailsDialogComponent, {
            width: '100em',
            height: '50em',
            data: dataToPass
            // data: { questions: this.addForm.value, sectionIndex: index }
        });
        // const dialogRef = this.dialog.open(AddJobNotesAttachmentDialogComponent, {
        //     // width: '100em',
        //     // height: '50em',
        //     data: dataToPass
        //     // data: { questions: this.addForm.value, sectionIndex: index }
        // });

        dialogRef.afterClosed().subscribe(result => {
            this.ref.reattach();
            console.log(result);
            this.ngOnInit();
            console.log('aw')
            console.log(this.actionList);
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                let redirectTo = this.currentUrl;
                this.router.navigate([redirectTo]);
            });
        });
    }


    openDialogViewJobDetails(data): void {
        console.log(data)
        this.ref.detach();
        let dataToPass = {
            history: [],
            note: [],
            attachments: [],
            linkedJobs: []
        }

        const dialogRef = this.dialog.open(ViewJobDetailsDialogComponent, {
            width: '85em',
            height: '40em',
            data: data
            // data: { questions: this.addForm.value, sectionIndex: index }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            this.ref.reattach();
            this.actionListValue = 'Select Action...';
            this.loadProducts();
            
            console.log(this.actionListValue);
            console.log(this.currentUrl);
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                let redirectTo = this.currentUrl;
                this.router.navigate([redirectTo]);
            });
        });
    }

    private loadMore(): void {
        const next = this.mappedData.length;
        this.gridData = [
            ...this.mappedData,
            ...this.data.slice(next, next + 50)
        ];
    }

    openAssignJob(data, type): void {
        console.log(data);
        if(type == 'single') {
            this._httpRequest.get('/api/user/qualified/myusers/' + data.id).subscribe((ret: any) => {
                const dialogRef = this.dialog.open(AssignJobUserComponent, {
                    // width: '50em',
                    // height: '13em',
                    data: {jobId: data.id, data:ret, selectedUser: data.user, type: 'single-assign'},
                    // data: { questions: this.addForm.value, sectionIndex: index }
                });
                
                dialogRef.afterClosed().subscribe(result => {
                    console.log(result);
                    this.ngOnInit();
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                        let redirectTo = this.currentUrl;
                        this.router.navigate([redirectTo]);
                    });
                    // window.location.reload();
                    // this.loadProducts();
                    // this.loadMore();
                    // this.processJobStatus();
                });
            })
        } else if(type == 'single-reassign') {
            this._httpRequest.get('/api/user/qualified/myusers/' + data.id).subscribe((ret: any) => {
                const dialogRef = this.dialog.open(AssignJobUserComponent, {
                    // width: '50em',
                    // height: '13em',
                    data: {jobId: data.id, data:ret, selectedUser: data.user, type: 'single-reassign'},
                    // data: { questions: this.addForm.value, sectionIndex: index }
                });
                
                dialogRef.afterClosed().subscribe(result => {
                    console.log(result);
                    this.ngOnInit();
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                        let redirectTo = this.currentUrl;
                        this.router.navigate([redirectTo]);
                    });
                    // window.location.reload();
                    // this.loadProducts();
                    // this.loadMore();
                    // this.processJobStatus();
                });
            })
        } else if(type == 'reassign-batch') {
            let users = [];
            for(let x = 0; x < data.length; x++) {
                if(users.length == 0) {
                    users.push(data[x].user);
                } else {
                    console.log(data[x].user.id, users)
                    if(this.containsObject(data[x].user.id, users)) {

                    } else {
                        users.push(data[x].user)
                    }
                }
            }
            let stringIds = '';
            for(let x = 0; x < data.length; x++) {
                if(x == 0) {
                    stringIds = stringIds.concat('equipmentIds=' + data[x].equipmentId);
                } else {
                    stringIds = stringIds.concat('&equipmentIds=' + data[x].equipmentId);
                }
            }
            console.log(stringIds);
            this._httpRequest.get('/api/user/qualified/myusers/equipment?' + stringIds).subscribe((ret: any) => {
                console.log(ret);
                const dialogRef = this.dialog.open(AssignJobUserComponent, {
                    // width: '50em',
                    // height: '13em',
                    data: {jobId: this.selectedAssignedJobs, data:ret, selectedUser: users, type: type}
                });
                dialogRef.afterClosed().subscribe(result => {
                    console.log(result);
                    this.ngOnInit();
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                        let redirectTo = this.currentUrl;
                        this.router.navigate([redirectTo]);
                    });

                }); 
            })
        } else if(type == 'reassign-rebook-batch') {
            let users = [];
            for(let x = 0; x < this.selectedBookedJobs.length; x++) {
                if(users.length == 0) {
                    users.push(this.selectedBookedJobs[x].user);
                } else {
                    console.log(this.selectedBookedJobs[x].user.id, users)
                    if(this.containsObject(this.selectedBookedJobs[x].user.id, users)) {

                    } else {
                        users.push(this.selectedBookedJobs[x].user)
                    }
                }
            }
            let stringIds = '';
            for(let x = 0; x < this.selectedBookedJobs.length; x++) {
                if(x == 0) {
                    stringIds = stringIds.concat('equipmentIds=' + this.selectedBookedJobs[x].equipmentId);
                } else {
                    stringIds = stringIds.concat('&equipmentIds=' + this.selectedBookedJobs[x].equipmentId);
                }
            }
            this._httpRequest.get('/api/user/qualified/myusers/equipment?' + stringIds).subscribe((ret: any) => {
                console.log(ret);
                const dialogRef = this.dialog.open(AssignJobUserComponent, {
                    // width: '50em',
                    // height: '13em',
                    data: {jobId: this.selectedBookedJobs, data:ret, selectedUser: users, type: type}
                });
                dialogRef.afterClosed().subscribe(result => {
                    console.log(result);
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                        let redirectTo = this.currentUrl;
                        this.router.navigate([redirectTo]);
                    });

                });
            })
        } else if(type == 'Reassign and Rebook Job') {
            
            this._httpRequest.get('/api/user/qualified/myusers/' + data.id).subscribe((ret: any) => {
                const dialogRef = this.dialog.open(AssignJobUserComponent, {
                    // width: '50em',
                    // height: '13em',
                    data: {jobId: data.id, data:ret, selectedUser: data.user, type: 'reassign-rebook-single'},
                });
                
                dialogRef.afterClosed().subscribe(result => {
                    console.log(result);
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                        let redirectTo = this.currentUrl;
                        this.router.navigate([redirectTo]);
                    });
                });
            })
        } else {
            console.log(data);
            let stringIds = '';
            for(let x = 0; x < data.length; x++) {
                if(x == 0) {
                    stringIds = stringIds.concat('equipmentIds=' + data[x].equipmentId);
                } else {
                    stringIds = stringIds.concat('&equipmentIds=' + data[x].equipmentId);
                }
            }
            console.log(stringIds);
            this._httpRequest.get('/api/user/qualified/myusers/equipment?' + stringIds).subscribe((ret: any) => {
                console.log(ret);
                const dialogRef = this.dialog.open(AssignJobUserComponent, {
                    // width: '50em',
                    // height: '13em',
                    data: {jobId: this.selectedUnassignedJobs, data:ret, selectedUser: [], type: 'assign-batch'}
                });
                dialogRef.afterClosed().subscribe(result => {
                    console.log(result);
                    this.ngOnInit();
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                        let redirectTo = this.currentUrl;
                        this.router.navigate([redirectTo]);
                    });

                });
            })
        }
        
    }

    openDialogBookedJobs(data, type) {
        console.log(data);
        console.log(type);
        let stringIds = '';
            for(let x = 0; x < data.length; x++) {
                if(x == 0) {
                    stringIds = stringIds.concat('equipmentIds=' + data[x].equipmentId);
                } else {
                    stringIds = stringIds.concat('&equipmentIds=' + data[x].equipmentId);
                }
            }
            console.log(stringIds);
            this._httpRequest.get('/api/user/qualified/myusers/equipment?' + stringIds).subscribe((ret: any) => {
                console.log(ret);
                if(type == 'single-book') {
                    const dialogRef = this.dialog.open(BookedJobDialogComponent, {
                        // width: '50em',
                        // height: '13em',
                        data: {jobs: data, user: ret, type: 'single-book' }
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        console.log(result);
                        this.ngOnInit();
                        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                            let redirectTo = this.currentUrl;
                            this.router.navigate([redirectTo]);
                        });
            
                    });
                } else if(type == 'batch-assign') {
                    const dialogRef = this.dialog.open(BookedJobDialogComponent, {
                        // width: '50em',
                        // height: '13em',
                        data: {jobs: data, user: ret, type: 'batch-assign' }
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        console.log(result);
                        this.ngOnInit();
                        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                            let redirectTo = this.currentUrl;
                            this.router.navigate([redirectTo]);
                        });
            
                    });
                } else if(type == 'single-assign') {
                    const dialogRef = this.dialog.open(BookedJobDialogComponent, {
                        // width: '50em',
                        // height: '13em',
                        data: {jobs: data, user: ret, type: 'single-assign-book' }
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        console.log(result);
                        this.ngOnInit();
                        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                            let redirectTo = this.currentUrl;
                            this.router.navigate([redirectTo]);
                        });
                    });
                } else if(type == 'single-assign-book') {
                    const dialogRef = this.dialog.open(BookedJobDialogComponent, {
                        // width: '50em',
                        // height: '13em',
                        data: {jobs: data, user: ret, type: 'single-assign-book' }
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        console.log(result);
                        this.ngOnInit();
                        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                            let redirectTo = this.currentUrl;
                            this.router.navigate([redirectTo]);
                        });
                    });
                } else if(type == 'rebook-single-job') {
                    const dialogRef = this.dialog.open(BookedJobDialogComponent, {
                        // width: '50em',
                        // height: '13em',
                        data: {jobs: data, user: ret, type: 'rebook-single-job' }
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        console.log(result);
                        this.ngOnInit();
                        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                            let redirectTo = this.currentUrl;
                            this.router.navigate([redirectTo]);
                        });
                    });
                } else if(type == 'rebook-batch-job') {
                    console.log(data)
                    console.log(ret)
                    const dialogRef = this.dialog.open(BookedJobDialogComponent, {
                        // width: '50em',
                        // height: '13em',
                        data: {jobs: data, user: ret, type: 'rebook-batch-job' }
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        console.log(result);
                        this.ngOnInit();
                        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                            let redirectTo = this.currentUrl;
                            this.router.navigate([redirectTo]);
                        });
                    });
                } else if(type == 'single-reassign-book') {
                    const dialogRef = this.dialog.open(BookedJobDialogComponent, {
                        // width: '50em',
                        // height: '13em',
                        data: {jobs: data, user: ret, type: 'single-reassign-book' }
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        console.log(result);
                        this.ngOnInit();
                        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                            let redirectTo = this.currentUrl;
                            this.router.navigate([redirectTo]);
                        });
                    });
                } else if(type == 'book-batch-job') {
                    const dialogRef = this.dialog.open(BookedJobDialogComponent, {
                        // width: '50em',
                        // height: '13em',
                        data: {jobs: data, user: ret, type: 'book-batch-job' }
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        console.log(result);
                        this.ngOnInit();
                        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                            let redirectTo = this.currentUrl;
                            this.router.navigate([redirectTo]);
                        });
                    });
                } else if(type == 'batch-reassign-book') {
                    console.log(data);
                    console.log(ret);
                    let users = [];
                    for(let x = 0; x < data.length; x++) {
                        if(users.length == 0) {
                            users.push(data[x].user);
                        } else {
                            if(this.containsObject(data[x].user.id, users)) {
        
                            } else {
                                users.push(data[x].user)
                            }
                        }
                    }
                    console.log(users);
                    const dialogRef = this.dialog.open(BookedJobDialogComponent, {
                        // width: '50em',
                        // height: '13em',
                        data: {jobs: data, user: ret, selectedUsers: users, type: 'batch-reassign-book' }
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        console.log(result);
                        this.ngOnInit();
                        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                            let redirectTo = this.currentUrl;
                            this.router.navigate([redirectTo]);
                        });
                    });
                } else {
                    const dialogRef = this.dialog.open(BookedJobDialogComponent, {
                        // width: '50em',
                        // height: '13em',
                        data: {jobs: data, user: ret, type: 'batch' }
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        console.log(result);
                        this.ngOnInit();
                        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                            let redirectTo = this.currentUrl;
                            this.router.navigate([redirectTo]);
                        });
                    });
                }
                
            })
    }

    openDialogViewJobDetailsViaCell(e) {
        console.log(e);
        let data = e.dataItem;
        let dataToPass = {
            history: [],
            note: [],
            attachments: [],
            linkedJobs: []
        }

        const dialogRef = this.dialog.open(ViewJobDetailsDialogComponent, {
            width: '85em',
            height: '40em',
            data: data
            // data: { questions: this.addForm.value, sectionIndex: index }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            this.actionListValue = 'Select Action...';
            this.loadProducts();
            
            console.log(this.actionListValue);
            console.log(this.currentUrl);
            // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            //     let redirectTo = this.currentUrl;
            //     this.router.navigate([redirectTo]);
            // });
        });
       
    }

    selectAction(value: any, data) {
        console.log(value);
        console.log(data);
        // ['View Detail', 'View Results', 'Download Excel', 'Download PDF', 'Assign to User', 'Add Note', 'Add Attachment'];
        if(value == "View Detail") {
            this.openDialogViewJobDetails(data);
        } else if(value == "View Results") {
            this.openDialogPreview(data);
        } else if(value == "Assign to User" || value == "Assign Job" || value == 'Reassign Job' || value == 'Reassign and Rebook Job') {
            if(value == 'Reassign and Rebook Job') {
                this.openAssignJob(data, 'Reassign and Rebook Job');
            } else if(value == 'Reassign Job') {
                this.openAssignJob(data, 'single-reassign');
            } else {
                this.openAssignJob(data, 'single');
            }

            // if(data.StatusId) {
            //     this.openAssignJob(data, 'single');
            // } else {
            //     Swal.fire("Error", "This Job has been assigned.", "warning").then((result) => {

            //     })
            // }
        } else if(value == "Assign Batch Jobs" || value == "Reassign Batch Jobs" || value == 'Reassign and Rebook Batch Jobs') {
            if(value == "Assign Batch Jobs") {
                this.openAssignJob(this.selectedUnassignedJobs, 'batch');
            } else if(value == "Reassign and Rebook Batch Jobs") {
                this.openAssignJob(this.selectedAssignedJobs, 'reassign-rebook-batch');
            } else {
                this.openAssignJob(this.selectedAssignedJobs, 'reassign-batch');
            }
        } else if(value == "Add Note") {
            this.openDialogaddJob('note', data);
        } else if(value == "Add Attachment") {
            this.openDialogaddJob('attachment', data);
        } else if(value == "Download Excel") {
            let jobData = data;
            this.downloadFile(data).subscribe(data => this.downloadFileExcel(data, jobData)),
                error => console.log("Error downloading the file."), () => console.log("Error");
        } else if(value == "Download PDF") {
            let job = data;
            console.log(JSON.stringify(data));
            this.downloadFilePDF(data).subscribe(data => this.downloadFileDataPDF(data, job)),
                error => console.log("Error downloading the file."), () => console.log("Error");
            // this._httpRequest.get('/api/excel/job/' + data.Id).subscribe((resp: any) => {
            //     console.log(resp)
            //     this.downloadFile(resp);
            // });
        } else if(value == "Book Batch Jobs") {
            this.openDialogBookedJobs(this.selectedAssignedJobs, 'book-batch-job');
        } else if(value == "Book Job" || value == 'Rebook Job' || value == 'Rebook Batch Jobs') {
            if(value == "Book Job") {
                this.openDialogBookedJobs(data, 'single-book');
            } else if (value == "Rebook Job") {
                this.openDialogBookedJobs(data, 'rebook-single-job');
            } else if(value == 'Rebook Batch Jobs') {
                this.openDialogBookedJobs(this.selectedBookedJobs, 'rebook-batch-job');
            }
        } else if(value == "Assign and Book Batch Jobs" || value=="Reassign and Book Job" || value == "Reassign and Book Batch Jobs") {
            if(value == "Assign and Book Batch Jobs" ) {
                this.openDialogBookedJobs(this.selectedUnassignedJobs, 'batch-assign');
            } else if(value=="Reassign and Book Job") {
                this.openDialogBookedJobs(data, 'single-reassign-book');
            } else if(value == "Reassign and Book Batch Jobs") {
                this.openDialogBookedJobs(this.selectedAssignedJobs, 'batch-reassign-book');
            }
        } else if(value == "Assign and Book") {
            console.log(this.selectedUnassignedJobs);
            let jobData = [];
            jobData.push(data);
            this.selectedUnassignedJobs.push(data);
            this.openDialogBookedJobs(jobData, 'single-assign-book');
        }
    }

    downloadFile(data: any){
        let sUrl:string = "https://api.bcas.mfit.uk/api/excel/job/" + data.id;
        let headers = new HttpHeaders({
            'Authorization':'Bearer ' + localStorage.getItem('access_token')
        })
        return this.http.get(sUrl, { headers:headers, responseType:"blob"});
    }

    downloadFileExcel(data: any, job){
        let name:any;
        if(job.equipment) {
            name = job.equipment.assetNumber + '-' + job.equipment.site.name + '.xlsx'
        } else {
            name = 'Result.xlsx'
        }
        var url = window.URL.createObjectURL(new Blob([data]));
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = this.getYearMonthName(job.completionDate)+ '-' + name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }

      downloadFilePDF(data: any){
        let sUrl:string = "https://api.bcas.mfit.uk/api/excel/job/pdf/" + data.id;
        let headers = new HttpHeaders({
            'Authorization':'Bearer ' + localStorage.getItem('access_token')
        })
        return this.http.get(sUrl, { headers:headers, responseType:"blob"});
    }

    downloadFileDataPDF(data: any, job){
        let name: any;
        if(job.equipment && job.equipment) {
          name = job.equipment.assetNumber + '-' + job.equipment.site.address1 + '.pdf';
        } else {
          name = 'Job Details.pdf'
        }
        var url = window.URL.createObjectURL(new Blob([data]));
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = this.getYearMonthName(job.completionDate) + '-' + name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }

    setTabView(data) {
        console.log(data.index)
        localStorage.setItem('tableShowIndex', data.index);
    }

    onSelect(e) {
    if(e.selectedRows.length != 0) {
        if(this.selectedUnassignedJobs.length == 0) {
            this.selectedUnassignedJobs.push(e.selectedRows[0].dataItem)
        } else {
            this.selectedUnassignedJobs.push(e.selectedRows[0].dataItem)
        }
    } else {
        for(let x = 0 ; x < this.selectedUnassignedJobs.length; x++) {
            if(this.selectedUnassignedJobs[x].Id == e.deselectedRows[0].dataItem.Id) {
            this.selectedUnassignedJobs.splice(x, 1);
            }
        }
    }
    console.log(this.selectedUnassignedJobs);
    if(this.selectedUnassignedJobs.length > 1) {
        this.showBatchAction = true;
    } else {
        this.showBatchAction = false;
    }
    }

    onSelectAssigned(e) {
        if(e.selectedRows.length != 0) {
            if(this.selectedAssignedJobs.length == 0) {
                this.selectedAssignedJobs.push(e.selectedRows[0].dataItem)
            } else {
                this.selectedAssignedJobs.push(e.selectedRows[0].dataItem)
            }
        } else {
            for(let x = 0 ; x < this.selectedAssignedJobs.length; x++) {
                if(this.selectedAssignedJobs[x].Id == e.deselectedRows[0].dataItem.Id) {
                this.selectedAssignedJobs.splice(x, 1);
                }
            }
        }
        console.log(this.selectedAssignedJobs);
        if(this.selectedAssignedJobs.length > 1) {
            this.showBatchActionAssigned = true;
         }
        }

    onSelectBooked(e) {
        console.log(e);
        if(e.selectedRows.length != 0) {
            if(this.selectedBookedJobs.length == 0) {
                this.selectedBookedJobs.push(e.selectedRows[0].dataItem)
            } else {
                this.selectedBookedJobs.push(e.selectedRows[0].dataItem)
            }
        } else {
            for(let x = 0 ; x < this.selectedBookedJobs.length; x++) {
                if(this.selectedBookedJobs[x].Id == e.deselectedRows[0].dataItem.Id) {
                this.selectedBookedJobs.splice(x, 1);
                }
            }
        }
        console.log(this.selectedBookedJobs);
        if(this.selectedBookedJobs.length > 1) {
            this.showBatchActionBooked = true;
            } else {
            this.showBatchActionBooked = false;
            }
        }

        containsObject(id, list) {
            var i;
            for (i = 0; i < list.length; i++) {
              if (list[i].id === id) {
                  return true;
              }
            }
            return false;
          }

    getYearMonthName(date:string) {
        console.log(date);
        let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        let newDate = date.substring(0,4);
        let month = date.substring(5,7)
        console.log(newDate);
        console.log(month);
        let dateF: any;
        if(month == '01') {
            dateF = newDate + '-' + months[0];
        } else if(month == '02') {
            dateF = newDate + '-' + months[1];
        } else if(month == '03') {
            dateF = newDate + '-' + months[2];
        } else if(month == '04') {
            dateF = newDate + '-' + months[3];
        } else if(month == '05') {
            dateF = newDate + '-' + months[4];
        } else if(month == '06') {
            dateF = newDate + '-' + months[5];
        } else if(month == '07') {
            dateF = newDate + '-' + months[6];
        } else if(month == '08') {
            dateF = newDate + '-' + months[7];
        } else if(month == '09') {
            dateF = newDate + '-' + months[8];
        } else if(month == '10') {
            dateF = newDate + '-' + months[9];
        } else if(month == '11') {
            dateF = newDate + '-' + months[10];
        } else if(month == '12') {
            dateF = newDate + '-' + months[11];
        }
        console.log(dateF)
        return dateF;
    }

}
