import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileValidators } from 'ngx-file-drag-drop';
import Swal from 'sweetalert2';
import { HttpRequestsService } from './../../../services/http-requests.service';

@Component({
  selector: 'app-add-job-details',
  templateUrl: './add-job-details.component.html',
  styleUrls: ['./add-job-details.component.css']
})

export class AddJobDetailsDialogComponent {
  note: any;
  fileSrc = '';
  fileExtension = '';
  file = '';
  filesToSend = [];
  filesToSendFinal = []
  fileNames = [];
  fileControl = new FormControl(
    [],
    [FileValidators.required,
    FileValidators.maxFileCount(2)]
  );
  constructor(
    public dialogRef: MatDialogRef<AddJobDetailsDialogComponent>,
    protected _httpRequest: HttpRequestsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {


  }

  ngOnInit(): void {
    console.log(this.data);
    this.fileControl.valueChanges.subscribe((files: File[]) =>
      console.log(this.fileControl.value, this.fileControl.valid)
    );
  }

  onValueChange(file: File[]) {
    console.log("File changed!");
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit() {
    if(this.data.type == 'note') {
      let dataToSend = {
        Note: this.note,
        Date:  this.formatDateTime()
      }
      this._httpRequest.post(dataToSend, '/api/job/note/' + this.data.jobDetails.id).subscribe((ret) => {
        this.onNoClick();
        // Swal.fire(
        //   'Added!',
        //   'Note has been added to the successfuly.',
        //   'success'
        // ).then((result) => {
        //   this.onNoClick();
        //   });
        })
  
      } else if(this.data.type == 'attachment') {
        let dataToSend = [];
        for (let x = 0; x < this.filesToSend.length; x++) {
          this.getBase64(this.filesToSend[x]);
        }
        for(let x = 0; x < this.fileNames.length; x++) {
          this.filesToSendFinal[x]['Name'] = this.fileNames[x]
        }
        for(let x = 0; x < this.filesToSendFinal.length; x++) {
          console.log(this.filesToSendFinal[x].Type);
          if(this.filesToSendFinal[x].Type.includes('application/')) {
            console.log('true')
            this.filesToSendFinal[x].Type = this.filesToSendFinal[x].Type.replace('application/', '')
          }
          dataToSend.push({
            "name": this.filesToSendFinal[x].Name,
            "type": this.filesToSendFinal[x].Type,
            "file": this.filesToSendFinal[x].File,
          })
        }
        console.log(dataToSend);
        console.log(JSON.stringify(dataToSend));
        this._httpRequest.post(dataToSend, '/api/job/attachment/' + this.data.jobDetails.id).subscribe((ret) => {
          Swal.fire(
            'Added!',
            'Attachment has been added to the successfuly.',
            'success'
          ).then((result) => {
            this.onNoClick();
            });
          })
      }
    }

    getBase64(data) {
      console.log(data);
      let fileType = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'image/jpeg',
        'image/jpg',
        'image/png',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ];
  
      for(let x = 0; x < fileType.length; x++) {
        if(data.includes(fileType[x])){
          this.fileSrc = data.replace(fileType[x], '');
          if(fileType[x].includes('image/')) {
            this.filesToSendFinal.push({
              File: this.fileSrc = this.fileSrc.replace('data:;base64,',''),
              Type: fileType[x].replace('image/', ''),
            });
          } else {
            this.filesToSendFinal.push({
              File: this.fileSrc = this.fileSrc.replace('data:;base64,',''),
              Type: fileType[x],
            });
          }
        }
      }
    }

    fileChangeEvent(e) {
      console.log(e)
      for(let x = 0; x < e.target.files.length; x++) {
        var file = e.dataTransfer ? e.dataTransfer.files[x] : e.target.files[x];
        var reader = new FileReader();
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(file);
        this.fileNames.push(file.name);
        console.log(file.name);
        console.log(this.fileNames);
        this.getFilename(file.name)
      }
    }

    fileChangeEvents(files: File[]) {
      console.log(files)
      for(let x = 0; x < files.length; x++) {
        var file = files[x];
        var reader = new FileReader();
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(file);
        this.fileNames.push(file.name);
        console.log(file.name);
        console.log(this.fileNames);
        this.getFilename(file.name)
      }
    }

    _handleReaderLoaded(e) {
      let reader = e.target;
      this.fileSrc = reader.result;
      this.filesToSend.push(reader.result);
      // this.getBase64(reader.result)
    }
    getFilename(filename) {
      this.fileExtension = '.' + filename.split('.').pop();
    }

    formatDateTime() {
      const now = new Date();
      let dateTimeformat: any = '';
      dateTimeformat = now.getFullYear() + "-" + String(now.getMonth() + 1).padStart(2, '0') + "-" + String(now.getDate()) + "T" + String(String(now.getHours()).padStart(2, '0') + ":" + String(now.getMinutes()).padStart(2, '0') + ":" + String(now.getSeconds()).padStart(2, '0'));
      // console.log(dateTimeformat);
      return dateTimeformat
    }
}
