import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LabelModule } from '@progress/kendo-angular-label';

import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { MatSliderModule } from '@angular/material/slider';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SignaturePadModule } from 'angular2-signaturepad';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { IconsModule } from '@progress/kendo-angular-icons';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { MatFileUploadModule } from 'angular-material-fileupload';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { JobsProcessComponent } from './jobs-process/jobs-process.component'
import { JobsDataGridComponent } from './jobs-data-grid/jobs-data-grid.component'
import { SchedulerComponent } from './scheduler/scheduler.component';

import { SanitizeHtmlDirective } from './jobs-process/sanitize-html.directive';
// import { CreateJobComponent } from './create-job/create-job.component';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    GridModule,
    PDFModule,
    PDFModule,
    LabelModule,
    DropDownsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatDividerModule,
    MatNativeDateModule,
    MatCardModule,
    MatTabsModule,
    MatIconModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatListModule,
    MatSliderModule,
    MatPaginatorModule,
    MatTableModule,
    DragDropModule,
    SignaturePadModule,
    ButtonsModule,
    IconsModule,
    LayoutModule,
    SchedulerModule,
    DateInputsModule,
    MatFileUploadModule,
    NgbModule
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    JobsProcessComponent,
    JobsDataGridComponent,
    SchedulerComponent,
    SanitizeHtmlDirective,
    // CreateJobComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    JobsProcessComponent,
    SanitizeHtmlDirective,
    JobsDataGridComponent,
    SchedulerComponent
  ]
})
export class ComponentsModule { }
