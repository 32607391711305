import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpRequestsService } from 'app/services/http-requests.service';


@Component({
    selector: 'app-add-event',
    templateUrl: './add-event.component.html',
    styleUrls: ['./add-event.component.css']
})

export class AddEventComponent implements OnInit {
    eventForm: FormGroup;
    calendarEventType: any;
    public startDate: Date = new Date(Date.now());
    public endDate: Date = new Date(Date.now());
    public format = "MM/dd/yyyy HH:mm";
    constructor(
      public dialogRef: MatDialogRef<AddEventComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder,
        protected _httpRequest: HttpRequestsService,
    ) {

    }
    ngOnInit() {
        console.log(this.data)
        this.initForm();
        this.getCalendarTypeEvent();
    }


    initForm() {
        this.eventForm = this._formBuilder.group({
          title: ['', Validators.compose([
            Validators.required,
          ])],
          calendarTypeEvent: ['', Validators.compose([
            Validators.required,
          ])],
          startDate: [''],
          endDate: [''],
          repeatInterval: [''],
          userId: ['']
        })
    }

    getCalendarTypeEvent() {
        this._httpRequest.get('/api/calendar/event/type').subscribe((resp: any) => {
            console.log(resp)
           this.calendarEventType = resp;
          }, err => {
          });
    }

    addEvent() {
        console.log(this.eventForm.controls.title.value);
        let dataToSend = {
          title: this.eventForm.controls.title.value,
          calendarEventType: this.eventForm.controls.calendarTypeEvent.value,
          startTime: this.formatDateTime(this.startDate),
          endTime: this.formatDateTime(this.endDate),
          userId: this.data.data.userId
        }
        console.log(dataToSend);
        // // this._httpRequest.post(dataToSend, '​/api​/user​/calendar​/event').subscribe((resp: any) => {
        //   this._httpRequest.post(dataToSend, '/api/user/calendar/event').subscribe((resp: any) => {
        //   console.log(resp)
        // //  this.calendarEventType = resp;
        // this.initForm();
        // this.getCalendarTypeEvent();
        // this.dialogRef.close('');
        // }, err => {
        // });
    }

    formatDateTime(date) {
      const now = date;
      let dateTimeformat: any = '';
      let day: any;
      let month: any;
      let time: any;
      if((now.getMonth() + 1) == 1 ||(now.getMonth() + 1) == 2 ||(now.getMonth() + 1) == 3 ||(now.getMonth() + 1) == 4 ||(now.getMonth() + 1) == 5 ||(now.getMonth() + 1) == 6 ||(now.getMonth() + 1) == 7 ||(now.getMonth() + 1) == 8 ||(now.getMonth() + 1) == 9) {
        month = "0" + (now.getMonth() + 1);
      } else {
        month = now.getMonth() + 1;
      }
    
      if(now.getDate() == 1 ||now.getDate() == 2 ||now.getDate() == 3 ||now.getDate() == 4 ||now.getDate() == 5 ||now.getDate() == 6 ||now.getDate() == 7 ||now.getDate() == 8 ||now.getDate() == 9){
        day = "0" + now.getDate();
      } else {
        day = now.getDate();
      }
    
     
      if(now.getSeconds() == 0 || now.getSeconds() == 1 || now.getSeconds() == 2 || now.getSeconds() == 3 || now.getSeconds() == 4 || now.getSeconds() == 5 || now.getSeconds() == 6 || now.getSeconds() == 7 || now.getSeconds() == 8 || now.getSeconds() == 9) {
        time = "T" + now.getHours() + ":" + now.getMinutes() + ":" + "0" + now.getSeconds();
      } else if(now.getMinutes() == 0 || now.getMinutes() == 1 || now.getMinutes() == 2 || now.getMinutes() == 3 || now.getMinutes() == 4 || now.getMinutes() == 5 || now.getMinutes() == 6 || now.getMinutes() == 7 || now.getMinutes() == 8 || now.getMinutes() == 9) {
        time = "T" + now.getHours() + ":" + "0" + now.getMinutes() + ":" + now.getSeconds();
      } else if(now.getHours() == 0 || now.getHours() == 1 || now.getHours() == 2 || now.getHours() == 3 || now.getHours() == 4 || now.getHours() == 5 || now.getHours() == 6 || now.getHours() == 7 || now.getHours() == 8 || now.getHours() == 9) {
        time = "T" + "0"  + now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds();
      } else if (now.getSeconds() == 0 || now.getSeconds() == 1 || now.getSeconds() == 2 || now.getSeconds() == 3 || now.getSeconds() == 4 || now.getSeconds() == 5 || now.getSeconds() == 6 || now.getSeconds() == 7 || now.getSeconds() == 8 || now.getSeconds() == 9 && now.getMinutes() == 0 || now.getMinutes() == 1 || now.getMinutes() == 2 || now.getMinutes() == 3 || now.getMinutes() == 4 || now.getMinutes() == 5 || now.getMinutes() == 6 || now.getMinutes() == 7 || now.getMinutes() == 8 || now.getMinutes() == 9) {
        time = "T" + now.getHours() + ":" + "0" + now.getMinutes() + ":" + "0" + now.getSeconds();
      } else if(now.getSeconds() == 0 || now.getSeconds() == 1 || now.getSeconds() == 2 || now.getSeconds() == 3 || now.getSeconds() == 4 || now.getSeconds() == 5 || now.getSeconds() == 6 || now.getSeconds() == 7 || now.getSeconds() == 8 || now.getSeconds() == 9 && now.getHours() == 0 || now.getHours() == 1 || now.getHours() == 2 || now.getHours() == 3 || now.getHours() == 4 || now.getHours() == 5 || now.getHours() == 6 || now.getHours() == 7 || now.getHours() == 8 || now.getHours() == 9) {
        time = "T" + "0" + now.getHours() + ":" + now.getMinutes() + ":" + "0" + now.getSeconds();
      } else if (now.getMinutes() == 0 || now.getMinutes() == 1 || now.getMinutes() == 2 || now.getMinutes() == 3 || now.getMinutes() == 4 || now.getMinutes() == 5 || now.getMinutes() == 6 || now.getMinutes() == 7 || now.getMinutes() == 8 || now.getMinutes() == 9 && now.getHours() == 0 || now.getHours() == 1 || now.getHours() == 2 || now.getHours() == 3 || now.getHours() == 4 || now.getHours() == 5 || now.getHours() == 6 || now.getHours() == 7 || now.getHours() == 8 || now.getHours() == 9) {
        time = "T" + "0" + now.getHours() + ":" + "0" + now.getMinutes() + ":" + now.getSeconds();
      } else {
        time = "T" + now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds();
      }
    
      dateTimeformat = now.getFullYear() + "-" + month + "-" + day + time;
      // console.log(dateTimeformat);
      return dateTimeformat
    }
}