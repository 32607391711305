import { Injectable } from '@angular/core';

@Injectable()
export class DataShareService {

  traderData: any = {};
  Selectedlistingview: any;
  
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  userInfo: any = {};

  constructor() {
    this.traderData.accessFlag = false;
    // this.userInfo.fullname = localStorage.getItem('full_name');
    // this.userInfo.email = localStorage.getItem('email');
  }
  setSelectedUser(data) {
 
  this.userInfo = data;
  }

  getSelectedUser(): any {
    return this.userInfo;
  }
 

}
