// Modules
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import {
  AgmCoreModule
} from '@agm/core';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from "ngx-ui-loader";
import { HttpInterceptor} from '@angular/common/http';
// import { NgxStarRatingModule } from 'ngx-star-rating';

// Component Pages
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
// Services
import { HttpRequestsService } from './services/http-requests.service';
import { HeaderConfigService } from './services/header-config.service';
import { AuthGuardService } from './services/auth-guard.service';
import { NavComponentsService } from './services/nav-components.service';
import { DataShareService } from './services/data-share.service';
import { GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { DataService } from './services/request-kendo.service';
import { IconsModule } from '@progress/kendo-angular-icons';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { TokenInterceptorService } from './token-interceptor.service';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
import { EditService } from './components/scheduler/edit-service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonsModule } from '@progress/kendo-angular-buttons';





@NgModule({
  imports: [
    GridModule,
    PDFModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule,
    NgxUiLoaderRouterModule,
    AgmCoreModule.forRoot({
      apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
    }),
    IconsModule,
    LayoutModule,
    SchedulerModule,
    NgbModule,
    ButtonsModule,
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
  ],
  providers: [
    AuthGuardService,
    HeaderConfigService,
    HttpRequestsService,
    NavComponentsService,
    DataShareService,
    DataService,
    EditService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
