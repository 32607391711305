import { Component, OnInit, Inject, ViewChild  } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { ThrowStmt } from '@angular/compiler';
import { filter } from 'rxjs/operators';

import { NgxUiLoaderService } from "ngx-ui-loader";
import { ConnectionService } from 'ng-connection-service'; 

import { HttpRequestsService } from '../../services/http-requests.service';
import { DialogStartJobDialog } from './start-job/start-job.component';
import { DialogSubmitJobAnswerDialog } from './submit-job-answer/submit-job-answer';
import { DialogContinueJobDialog } from './continue-job/continue-job.component';
import { State, process } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridDataResult, SelectableSettings } from '@progress/kendo-angular-grid';
import { ViewJobDetailsDialogComponent } from '../jobs/job-details/job-details.component';
import { JobSurveyPreviewDialogComponent } from '../jobs/preview/preview.component';
import { TileLayoutGap } from '@progress/kendo-angular-layout';
import { BookedJobDialogComponent } from '../jobs/booked-job/booked-job.component';
@Component({
  selector: 'app-myjobs',
  templateUrl: './my-jobs.component.html',
  styleUrls: ['./my-jobs.component.css']
})

export class MyJobsComponent implements OnInit {

  status = 'ONLINE';
  isConnected = true;

  display = 'assigned';
  showData = false;
  debugView = false;
  assignedJobs = [];
  bookJobs = [];
  inProgressJobs = [];
  pendingReviewJobs = [];
  pausedJobs = [];
  checkedPendingJobs = [];
  failedJobs = [];
  completedJobs = [];
  notPresentedJobs = [];
  allSurvey = [];
  apiQueue = [];
  hasQueue: boolean = false;
  surveySettings;
  order = 'assignedJobs.Id';
  reverse = false;
  public mySelectedPending: number[] = [];
  currentUrl: string = '';
  public mySelectedAssigned: number[] = [];
  public mySelectedBooked: number[] = [];
  public gap: TileLayoutGap = {
    rows: 25,
    columns: 40,
  };

  public state: State = {
    skip: 0,
    // take: 5,
  
    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [{ field: 'Id', operator: 'contains', value: '' }]
    }
  };

  public stateInProgressJobs: State = {
    skip: 0,
    // take: 5,
  
    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [{ field: 'Id', operator: 'contains', value: '' }]
    }
  };

  public statePausedJobs: State = {
    skip: 0,
    // take: 5,
  
    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [{ field: 'Id', operator: 'contains', value: '' }]
    }
  };

  public statePendingJobs: State = {
    skip: 0,
    // take: 5,
  
    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [{ field: 'Id', operator: 'contains', value: '' }]
    }
  };

  public stateFailedJobs: State = {
    skip: 0,
    // take: 5,
  
    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [{ field: 'Id', operator: 'contains', value: '' }]
    }
  };

  public stateCompletedJobs: State = {
    skip: 0,
    // take: 5,
  
    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [{ field: 'Id', operator: 'contains', value: '' }]
    }
  };

  public stateBookedJobs: State = {
    skip: 0,
    // take: 5,
  
    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [{ field: 'Id', operator: 'contains', value: '' }]
    }
  };

  public stateNotPresentedJobs: State = {
    skip: 0,
    // take: 5,
  
    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [{ field: 'Id', operator: 'contains', value: '' }]
    }
  };

  public assignedJobsData: GridDataResult;
  public inProgressJobsData: GridDataResult;
  public pausedJobsData: GridDataResult;
  public failedJobsData: GridDataResult;
  public completedJobsData: GridDataResult;
  public bookedJobsData: GridDataResult;
  public pendingJobsData: GridDataResult;
  public notPresentedJobsData: GridDataResult;

  public selectableSettings: SelectableSettings = {
    checkboxOnly: true,
    mode: 'multiple',
    drag: false
  };

  selectedJobPendingReview: any = [];

  constructor(
    protected _httpRequest: HttpRequestsService,
    private ngxLoader: NgxUiLoaderService,
    public dialog: MatDialog,
    private connectionService: ConnectionService,
    protected _router: Router,
  ) { }

  ngOnInit() {
    this.connectionService.monitor().subscribe(isConnected => {
      console.log(isConnected);
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = "ONLINE";
      }
      else {
        this.status = "OFFLINE";
      }
    })
    console.log(this.status);
    this.checkSurveySettings();
    this.getAllInProgressJobs();
    this.getAllPausedJobs();
    this.getAllAssignedJobs();
    this.getAllPendingJobs();
    this.getAllFailedJobs();
    this.getAllCompletedJobs();
    this.getAllBookedJobs();
    // this.getJobSurvey();
    this.checkDebugUrlParam();
    this.getFailStatus();
    this.getPauseStatus();
    this.getAllNotPresentedJobs();
    localStorage.removeItem('surveyAnswers');
  }

  checkDebugUrlParam() {
    if(this._router.url == '/dashboard/my-jobs?debug=true') {
      this.display = 'apiQueue';
      if(localStorage.getItem('debugFlag')){
        this.debugView = true;
      } else {
        localStorage.setItem('debugFlag', JSON.stringify('true'));
        this.debugView = true;
      }
      this.getQueueData();
    } else {
      this.debugView = false;
    }
  }

  getQueueData() {
    if(JSON.parse(localStorage.getItem('APIQueue'))){
      this.hasQueue = true;
      this.apiQueue = JSON.parse(localStorage.getItem('APIQueue'));
      console.log(this.apiQueue);
    } else {
      this.hasQueue = false;
    }
  }

  refreshData() {
    this.assignedJobs = [];
    this.bookJobs = [];
    this.inProgressJobs = [];
    this.pendingReviewJobs = [];
    this.completedJobs = [];
    this.allSurvey = [];
    localStorage.removeItem('userAllJobs');
    localStorage.removeItem('assignedJobs');
    localStorage.removeItem('bookJobs');
    localStorage.removeItem('inProgressJobs');
    // localStorage.removeItem('pendingReviewJobs');
    localStorage.removeItem('completedJobs');
    localStorage.removeItem('AllSurvey');
    localStorage.removeItem('pauseReasons');
    localStorage.removeItem('failReasons');
    this.checkSurveySettings();
    this.getAllInProgressJobs();
    this.getAllPausedJobs();
    this.getAllAssignedJobs();
    this.getAllPendingJobs();
    this.getAllFailedJobs();
    this.getAllCompletedJobs();
    this.getAllBookedJobs();
    // this.getJobSurvey();
    this.checkDebugUrlParam();
    this.getFailStatus();
    this.getPauseStatus();
    this.getAllNotPresentedJobs();
    localStorage.removeItem('surveyAnswers');
  }


  getAllAssignedJobs() {
    this._httpRequest.get('/api/job/find/user?PageNumber=0&OrderByAscending=true&JobStatusType=2').subscribe((ret:any) => {
      for(let x = 0; x < ret.length; x++) {
        ret[x].dueDate = new Date(new Date(ret[x].dueDate).setHours(0,0,0,0));
      }
      this.assignedJobs = ret;
      this.assignedJobsData = process(ret, this.state);
      this.showData = true;
      localStorage.setItem('inProgressJobs', JSON.stringify(this.inProgressJobs));
    });
  }

  getAllInProgressJobs() {
    this._httpRequest.get('/api/job/find/user?PageNumber=0&OrderByAscending=true&JobStatusType=7').subscribe((ret:any) => {
      for(let x = 0; x < ret.length; x++) {
        ret[x].dueDate = new Date(new Date(ret[x].dueDate).setHours(0,0,0,0));
      }
      this.inProgressJobs = ret;
      this.inProgressJobsData = process(ret, this.stateInProgressJobs);
      this.showData = true;
      localStorage.setItem('inProgressJobs', JSON.stringify(this.inProgressJobs));
    });
  }

  getAllPausedJobs() {
    this._httpRequest.get('/api/job/find/user?PageNumber=0&OrderByAscending=true&JobStatusType=8').subscribe((ret:any) => {
      for(let x = 0; x < ret.length; x++) {
        ret[x].dueDate = new Date(new Date(ret[x].dueDate).setHours(0,0,0,0));
      }
      this.pausedJobs = ret;
      this.pausedJobsData = process(ret, this.stateInProgressJobs);
      this.showData = true;
      localStorage.setItem('inProgressJobs', JSON.stringify(this.inProgressJobs));
    });
  }

  getAllPendingJobs() {
    this._httpRequest.get('/api/job/find/user?PageNumber=0&OrderByAscending=true&JobStatusType=10').subscribe((ret:any) => {
      for(let x = 0; x < ret.length; x++) {
        ret[x].dueDate = new Date(new Date(ret[x].dueDate).setHours(0,0,0,0));
      }
      this.pendingReviewJobs = ret;
      this.pendingJobsData = process(ret, this.statePendingJobs);
      this.showData = true;
      // localStorage.setItem('pendingReviewJobs', JSON.stringify(this.pendingReviewJobs));
    });
  }

  getAllFailedJobs() {
    this._httpRequest.get('/api/job/find/user?PageNumber=0&OrderByAscending=true&JobStatusType=9').subscribe((ret:any) => {
      for(let x = 0; x < ret.length; x++) {
        ret[x].dueDate = new Date(new Date(ret[x].dueDate).setHours(0,0,0,0));
      }
      this.failedJobs = ret;
      this.failedJobsData = process(ret, this.stateFailedJobs);
      this.showData = true;
      localStorage.setItem('failedJobs', JSON.stringify(this.failedJobs));
    });
  }

  getAllNotPresentedJobs() {
    this._httpRequest.get('/api/job/find/user?PageNumber=0&OrderByAscending=true&Status=CannotLocate').subscribe((ret:any) => {
      for(let x = 0; x < ret.length; x++) {
        ret[x].dueDate = new Date(new Date(ret[x].dueDate).setHours(0,0,0,0));
      }
      this.notPresentedJobs = ret;
      this.notPresentedJobsData = process(ret, this.stateNotPresentedJobs);
      this.showData = true;
      localStorage.setItem('notPresented', JSON.stringify(this.notPresentedJobs));
    });
  }

  getAllCompletedJobs() {
    this._httpRequest.get('/api/job/find/user?PageNumber=0&OrderByAscending=true&JobStatusType=11').subscribe((ret:any) => {
      for(let x = 0; x < ret.length; x++) {
        ret[x].dueDate = new Date(new Date(ret[x].dueDate).setHours(0,0,0,0));
      }
      this.completedJobs = ret;
      this.completedJobsData = process(ret, this.stateCompletedJobs);
      this.showData = true;
      localStorage.setItem('completedJobs', JSON.stringify(this.completedJobs));
    });
  }

  getAllBookedJobs() {
    this._httpRequest.get('/api/job/find/user?PageNumber=0&OrderByAscending=true&JobStatusType=5').subscribe((ret:any) => {
      for(let x = 0; x < ret.length; x++) {
        ret[x].dueDate = new Date(new Date(ret[x].dueDate).setHours(0,0,0,0));
      }
      this.bookJobs = ret;
      this.bookedJobsData = process(ret, this.stateBookedJobs);
      this.showData = true;
      localStorage.setItem('bookedJobs', JSON.stringify(this.bookJobs));
    });
  }

  getJobSurvey() {
    if(localStorage.getItem('AllSurvey')) {
      this.allSurvey = JSON.parse(localStorage.getItem('AllSurvey'));
    } else {
      // this._httpRequest.get('/api/surveys/').subscribe((ret:any) => {
        this._httpRequest.get('/api/Survey/').subscribe((ret:any) => {
        // localStorage.setItem('AllSurvey', JSON.stringify(ret));
        this.allSurvey = ret;
      });
    }
  }

  openSignaturePad(jobData) {
    console.log(jobData);
    let userSurvey: any;
    let dataToSend = {
      "signatureImage": '',
      "signatureName": '',
      "signatureFileExtension": '',
      "timeOfEvent": this.formatDateTime(),
      "JobStatusId": 0,
      "additionalDetailOrReason": '',
    }
    if(this.surveySettings.signOffOnStart) {
      this._httpRequest.get('/api/survey/' + jobData.surveyId).subscribe((ret:any) => {
        console.log(ret)
        userSurvey = ret;
        this._httpRequest.get('/api/user/testequipment/myequipment').subscribe((ret:any) => {
          localStorage.setItem('userTestEquipments', JSON.stringify(ret));
          const dialogRef = this.dialog.open(DialogStartJobDialog, {
          width: '100em',
          height: '60em',
          data: { jobData, jobSurvey: userSurvey },
          disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result === undefined) {
            this.refreshData();
          } else if(result == 'Pushed to Queue') {
            console.log(jobData);
            this.refreshData();
          }
        })
        });
      });
    } else {
      // this._httpRequest.post(dataToSend, '/api/job/process/start/' + jobData.id).subscribe((res: any) => { 
        this._httpRequest.get('/api/survey/' + jobData.surveyId).subscribe((ret:any) => {
          console.log(ret)
          userSurvey = ret;
          this._httpRequest.get('/api/user/testequipment/myequipment').subscribe((ret:any) => {
            localStorage.setItem('userTestEquipments', JSON.stringify(ret));
            const dialogRef = this.dialog.open(DialogStartJobDialog, {
            width: '100em',
            height: '60em',
            data: { jobData, jobSurvey: userSurvey },
            disableClose: true
          });
          dialogRef.afterClosed().subscribe(result => {
            if(result === undefined) {
              this.refreshData();
            } else if(result == 'Pushed to Queue') {
              console.log(jobData);
              this.refreshData();
            }
          })
          });
        });
      // })
    }
  }

  continueJob(jobData) {
    let userSurvey:any;
    // for(let x = 0 ; x < this.allSurvey.length; x++) {
    //   if(jobData.surveyId == this.allSurvey[x].id) {
    //     userSurvey = this.allSurvey[x];
    //   }
    // }
    console.log(userSurvey);
    console.log(jobData);
    this._httpRequest.get('/api/survey/' + jobData.surveyId).subscribe((ret:any) => {
      console.log(ret)
      userSurvey = ret;
      this._httpRequest.get('/api/user/testequipment/myequipment').subscribe((ret:any) => {
        localStorage.setItem('userTestEquipments', JSON.stringify(ret));
        const dialogRef = this.dialog.open(DialogContinueJobDialog, {
         width: '100em',
         height: '60em',
         // width: '570px',
         // height: '570px',
         data: { jobData, jobSurvey: userSurvey },
         disableClose: true
       });
       dialogRef.afterClosed().subscribe(result => {
        // this.assignedJobs = JSON.parse(localStorage.getItem('assignedJobs'));
        // this.inProgressJobs = JSON.parse(localStorage.getItem('inProgressJobs'));
        // this.completedJobs = JSON.parse(localStorage.getItem('completedJobs'));
        // this.getUserAllJobs();
        if(result === undefined) {
          this.refreshData();
        } else {
  
        }
      })
      });
    });
    // const dialogRef = this.dialog.open(DialogContinueJobDialog, {
    //   width: '100em',
    //   height: '50em',
    //   data: { jobData, jobSurvey: userSurvey },
    //   disableClose: true
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   // this.assignedJobs = JSON.parse(localStorage.getItem('assignedJobs'));
    //   // this.inProgressJobs = JSON.parse(localStorage.getItem('inProgressJobs'));
    //   // this.completedJobs = JSON.parse(localStorage.getItem('completedJobs'));
    //   // this.getUserAllJobs();
    //   if(result === undefined) {
    //     this.refreshData();
    //   } else {

    //   }
    // })
  }

  signSignature(type, data:any) {
    if(type == 'batchSign') {
      console.log(this.selectedJobPendingReview);
      const dialogRef = this.dialog.open(DialogSubmitJobAnswerDialog, {
        width: '100em',
        height: '50em',
        data:  this.selectedJobPendingReview,
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(result => {
        this.refreshData();
      })
    } else {
      const dialogRef = this.dialog.open(DialogSubmitJobAnswerDialog, {
        width: '100em',
        height: '50em',
        data:  Array(data),
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(result => {
        this.refreshData();
      })
    }
    
  }

  checked(event, job) {
    if(event.checked) {
      this.checkedPendingJobs.push(job);
    } else {
      this.checkedPendingJobs = this.checkedPendingJobs.filter(x=>x.Id!=job.Id);
    }
    console.log(this.checkedPendingJobs);
    // this.signSignature('batchSign', this.checkedPendingJobs)
  }

  batchSign() {
    this.signSignature('batchSign', this.checkedPendingJobs)
  }

   // FUNCTION NEEDED FOR MYJOBS FAIL AND PAUSE
   getFailStatus() {
     if(localStorage.getItem('failReasons')) {
     } else {
      this._httpRequest.get('/api/job/status/survey/fail').subscribe(ret => {
      console.log(ret);
      localStorage.setItem('failReasons', JSON.stringify(ret));
    });
     }
    
  }

  getPauseStatus() {
    if(localStorage.getItem('pauseReasons')) {

    } else {
      this._httpRequest.get('/api/job/status/survey/pause').subscribe(ret => {
        localStorage.setItem('pauseReasons', JSON.stringify(ret));
      // console.log(ret);
      // this.pauseReason = ret;
      // console.log(this.pauseReason);
    });
    }
    
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  checkSurveySettings() {
    if(localStorage.getItem('surveySettings')) {
      this.surveySettings = JSON.parse(localStorage.getItem('surveySettings'));
      console.log(this.surveySettings);
    } else {
      // this._httpRequest.get('/api/settings').subscribe((ret) => {
        this._httpRequest.get('/api/system/setting').subscribe((ret) => {
          this.surveySettings = ret;
          console.log(this.surveySettings);
          localStorage.setItem('surveySettings', JSON.stringify(ret));
    });
    }
  }

  onSelectPending(e) {
    console.log(this.mySelectedPending)
    console.log(this.pendingReviewJobs)
    this.selectedJobPendingReview = [];
    for(let x = 0; x < this.pendingReviewJobs.length; x++) {
      for(let y = 0; y < this.mySelectedPending.length; y++) {
        if(this.mySelectedPending[y] == this.pendingReviewJobs[x].id) {
          this.selectedJobPendingReview.push(this.pendingReviewJobs[x]);
        }
      }
    }
    console.log(this.selectedJobPendingReview);
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    console.log(state);
    this.state = state;
    this.assignedJobsData = process(this.assignedJobs, this.state);
  }

  public dataStateChangeBook(state: DataStateChangeEvent): void {
    console.log(state);
    this.stateBookedJobs = state;
    this.bookedJobsData = process(this.bookJobs, this.stateBookedJobs);
  }

  public dataStateChangeInProgress(state: DataStateChangeEvent): void {
    this.stateInProgressJobs = state;
    this.inProgressJobsData = process(this.inProgressJobs, this.stateInProgressJobs);
  }

  public dataStateChangePending(state: DataStateChangeEvent): void {
    this.statePendingJobs = state;
    this.pendingJobsData = process(this.pendingReviewJobs, this.statePendingJobs);
  }

  public dataStateChangeFailed(state: DataStateChangeEvent): void {
    console.log(state);
    this.stateFailedJobs = state;
    this.failedJobsData = process(this.failedJobs, this.stateFailedJobs);
  }

  public dataStateChangeNotPresented(state: DataStateChangeEvent): void {
    console.log(state);
    this.stateNotPresentedJobs = state;
    this.notPresentedJobsData = process(this.notPresentedJobs, this.stateNotPresentedJobs);
  }

  public dataStateChangeCompleted(state: DataStateChangeEvent): void {
    console.log(state);
    this.stateCompletedJobs = state;
    this.completedJobsData = process(this.completedJobs, this.stateCompletedJobs);
  }


  openDialogViewJobDetailsViaCell(e): void {
    console.log(e)
    let data = e.dataItem;
    let dataToPass = {
        data: '',
        answers: ''
    };
    this._httpRequest.get('/api/Survey/' + data.surveyId).subscribe((ret: any) => {
        console.log(ret);
        dataToPass.data = ret.sections
        this._httpRequest.get('/api/Answer/job/' + data.id).subscribe((ret: any) => {
            console.log(ret);
            dataToPass.answers = ret;
                const dialogRef = this.dialog.open(JobSurveyPreviewDialogComponent, {
                    width: '100em',
                    height: '50em',
                    data: { questions: dataToPass, data:data, job: data }
                    // data: { questions: this.addForm.value, sectionIndex: index }
                });

                dialogRef.afterClosed().subscribe(result => {
                    // console.log(result);
                    // console.log(this.currentUrl);
                    // // this.ngOnInit();
                    // this._router.navigateByUrl('/dashboard/my-jobs', { skipLocationChange: true }).then(() => {
                    //   let redirectTo = this.currentUrl;
                    //   this._router.navigate([redirectTo]);
                    // });
                });
        })
    })
  }

  onSelect(event) {
    console.log(event);
    console.log(this.mySelectedAssigned);
    console.log(this.mySelectedBooked);
  }

  bookSelectedJobs(value: any) {
    let selectedToBookJobs = [];
    for(let x = 0; x < this.assignedJobs.length; x++) {
      for(let y = 0; y < this.mySelectedAssigned.length; y++) {
        if(this.assignedJobs[x].id == this.mySelectedAssigned[y]) {
          selectedToBookJobs.push(this.assignedJobs[x]);
        }
      }
    }
    console.log(selectedToBookJobs);
    this.openDialogBookedJobs(selectedToBookJobs, 'book-batch-job');
  }

  openDialogBookedJobs(data, type) {
    const dialogRef = this.dialog.open(BookedJobDialogComponent, {
      // width: '50em',
      // height: '13em',
      data: {jobs: data, type: 'book-batch-job' }
    });
    dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        this.ngOnInit();
    });
  }

  formatDateTime() {
    const now = new Date();
    let dateTimeformat: any = '';
    dateTimeformat = now.getFullYear() + "-" + String(now.getMonth() + 1).padStart(2, '0') + "-" + String(now.getDate()) + "T" + String(String(now.getHours()).padStart(2, '0') + ":" + String(now.getMinutes()).padStart(2, '0') + ":" + String(now.getSeconds()).padStart(2, '0'));
    // console.log(dateTimeformat);
    return dateTimeformat
  }

}

