import { Component, OnInit, ElementRef, Input, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper/stepper';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import { HttpRequestsService } from 'app/services/http-requests.service';
import { SignaturePad } from 'angular2-signaturepad';
import { ConnectionService } from 'ng-connection-service';

import Swal from 'sweetalert2';

import { DialogFailJobDialog } from '../../layouts/my-jobs/fail-job/fail-job.component';
import { DialogStartJobDialog } from 'app/layouts/my-jobs/start-job/start-job.component';
import { DataStateChangeEvent, GridDataResult, SelectableSettings } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';

import { SchedulerEvent, Resource, DateChangeEvent, SlotClickEvent } from "@progress/kendo-angular-scheduler";
import { SchedulerComponent as Schedule } from "@progress/kendo-angular-scheduler";
import { sampleData, displayDate } from "./events-utc";
import { SchedulerModule, EventStyleArgs, CreateFormGroupArgs } from '@progress/kendo-angular-scheduler';
import { EditMode, CrudOperation } from '@progress/kendo-angular-scheduler'
import { AddEventComponent } from './add-event/add-event.component';


import {
  CancelEvent,
  EventClickEvent,
  RemoveEvent,
  SaveEvent,
} from '@progress/kendo-angular-scheduler';
import { EditService } from './edit-service';


@Component({
    selector: 'app-scheduler',
    templateUrl: './scheduler.component.html',
    styleUrls: ['./scheduler.component.css']
})
export class SchedulerComponent implements OnInit {

  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth();
  currentDate = new Date().getDate();
	// public selectedDate: any;
  selectedDate: Date = new Date("2018-10-22T00:00:00");
  // public events: SchedulerEvent[] = sampleData;
  eventsData: SchedulerEvent[];
  jobs: any = [];
  all_job = [];
  all_unassignedJobs = [];
  all_assignedJobs = [];
  all_engineerAcceptedJobs = [];
  all_inProgressJobs = [];
  all_pausedJobs = [];
  all_completeJobs = [];
  all_bookedJobs = [];
  userEvents = [];
  eventTypes = [];

  public value: Date = new Date(Date.now());
  public format = "MM/dd/yyyy HH:mm";

  users: any = [];
  selectedUser: any = '';

  public listItems: Array<{ text: string; value: number }> = [
    { text: "Small", value: 1 },
    { text: "Medium", value: 2 },
    { text: "Large", value: 3 },
  ];


  public formGroup: FormGroup;
  showAddEventButton = false;

  public events: SchedulerEvent[] = [
    {
      id: 1,
      title: "Breakfast",
      start: new Date("2018-10-22T09:00:00"),
      end: new Date("2018-10-22T09:30:00"),
      recurrenceRule: "FREQ=DAILY;COUNT=5;",
    },
  ];

  eventType: any;
  status: any;


  constructor(
    protected _httpRequest: HttpRequestsService,
    public formBuilder: FormBuilder,
    public dialog: MatDialog,
    public editService: EditService
  ) {
    this.createFormGroup = this.createFormGroup.bind(this);
  }

  ngOnInit() {
    console.log(this.currentYear)
    console.log(this.currentMonth)
    console.log(this.currentDate)
    this.selectedDate = new Date(this.currentYear, this.currentMonth, this.currentDate);
    this.checkForJobs();
    this.getUsers();
    this.getAllEventTypes();
  }

  public createFormGroup(args: CreateFormGroupArgs): FormGroup {
    const dataItem = args.dataItem;
    console.log(args)
    const isOccurrence = args.mode === EditMode.Occurrence;
    const exceptions = isOccurrence ? [] : dataItem.recurrenceExceptions;

    this.formGroup = this.formBuilder.group({
      id: args.isNew ? this.getNextId() : dataItem.id,
      start: [dataItem.start, Validators.required],
      end: [dataItem.end, Validators.required],
      startTimezone: [dataItem.startTimezone],
      endTimezone: [dataItem.endTimezone],
      isAllDay: dataItem.isAllDay,
      title: dataItem.title,
      description: dataItem.description,
      recurrenceRule: dataItem.recurrenceRule,
      // recurrenceRule: dataItem.recurrenceRule,
      recurrenceId: dataItem.recurrenceId,
      recurrenceExceptions: [exceptions]
    });

    return this.formGroup;
  }

  public isEditingSeries(editMode: EditMode): boolean {
    return editMode === EditMode.Series;
  }

  public getNextId(): number {
    const len = this.events.length;

    return len === 0 ? 1 : this.events[this.events.length - 1].id + 1;
  }

  checkForJobs() {
    if(JSON.parse(localStorage.getItem('JobsData'))) {
      this.jobs = JSON.parse(localStorage.getItem('JobsData'))
      console.log(this.jobs)
      // this.processData();
    } else {
      this.getAllJobs();
    }
  }

  processData() {
    // this.eventsData = this.jobs[0].bookedJobs.map(dataItem => (
    this.eventsData = this.userEvents.map(dataItem => (
      <SchedulerEvent> {
          id: dataItem.id,
          start: this.parseAdjust(dataItem.startTime),
          // startTimezone: dataItem.startTimezone,
          end: this.parseAdjust(dataItem.endTime),
          // endTimezone: dataItem.endTimezone,
          // isAllDay: dataItem.IsAllDay,
          // title: dataItem.title + ', for: ' + dataItem.user.email,
          title: dataItem.title,
          // description: dataItem.customer.name,
          recurrenceRule: dataItem.RecurrenceRule,
          // recurrenceId: dataItem.RecurrenceID,
          // recurrenceException: dataItem.RecurrenceException,
  
          // roomId: dataItem.RoomID,
          // ownerID: dataItem.OwnerID
      }
  ));
  } 

  parseAdjust = (eventDate: string): Date => {
    const date = new Date(eventDate);
    date.setFullYear(this.currentYear);
    return date;
  };

  getAllJobs() {
      this._httpRequest.get('/api/job/find?pagenumber=0&pagesize=100').subscribe((ret: any) => {
      this._httpRequest.get('/api/job/find/count').subscribe((resp: any) => {
        this.jobs.push({
          allJobs: ret.sort(this.compare),
          unassignedJobs: this.getUnassignedJobs(ret),
          assignedJobs: this.getAssignedJobs(ret),
          completedJobs: this.getCompletedJobs(ret),
          engineerAcceptedJobs: this.getEngineerAcceptedJobs(ret),
          inProgressJobs: this.getInProgressJobs(ret),
          bookedJobs: this.getBookedJobs(ret),
          allJobsCount: resp
        });
        localStorage.setItem('JobsData', JSON.stringify(this.jobs));
        // this.processData()
      }, err => {
      });
    });
  }

  getAllEventTypes() {
    this._httpRequest.get('/api/calendar/event/type').subscribe((resp: any) => {
      console.log(resp);
      this.eventTypes = resp;
      // this.processData()
    }, err => {
    });
  }

  compare(a, b) {
    const dueDateA = a.DueDate;
    const dueDateB = b.DueDate;
    let comparison = 0;
    if (dueDateA > dueDateB) {
      comparison = 1;
    } else if (dueDateA < dueDateB) {
      comparison = -1;
    }
    return comparison;
  }

  getUnassignedJobs(jobs) {
    for (var x = 0; x < jobs.length; x++) {
      if (jobs[x].statusId == 0 || jobs[x].statusId == 1) {
        this.all_unassignedJobs.push(jobs[x]);
      } else {

      }
    }
    return this.all_unassignedJobs;
  }

  getAssignedJobs(jobs) {
    for (var x = 0; x < jobs.length; x++) {
      if (jobs[x].statusId == 9) {
        this.all_assignedJobs.push(jobs[x]);
      } else {

      }
    }
    return this.all_assignedJobs;
  }

  getBookedJobs(jobs) {
    for (var x = 0; x < jobs.length; x++) {
      if (jobs[x].statusId == 3) {
        this.all_bookedJobs.push(jobs[x]);
      } else {

      }
    }
    return this.all_bookedJobs;
  }

  getEngineerAcceptedJobs(jobs) {
    for (var x = 0; x < jobs.length; x++) {
      if (jobs[x].statusId == 2) {
        this.all_engineerAcceptedJobs.push(jobs[x]);
      } else {

      }
    }
    return this.all_engineerAcceptedJobs;
  }

  getInProgressJobs(jobs) {
    for (var x = 0; x < jobs.length; x++) {
      if (jobs[x].statusId == 5) {
        this.all_inProgressJobs.push(jobs[x]);
      } else {

      }
    }
    return this.all_inProgressJobs;
  }

  getPausedJobs(jobs) {
    for (var x = 0; x < jobs.length; x++) {
      if (jobs[x].statusId == 5) {
        this.all_pausedJobs.push(jobs[x]);
      } else {

      }
    }
    return this.all_pausedJobs;
  }

  getCompletedJobs(jobs) {
    for (var x = 0; x < jobs.length; x++) {
      if (jobs[x].statusId == 38) {
        this.all_completeJobs.push(jobs[x]);
      } else {

      }
    }
    return this.all_completeJobs;
  }

  getUsers() {
    this._httpRequest.get('/api/user/manager').subscribe((resp: any) => {
      console.log(resp)
      this.users = resp;
    }, err => {
    });
  }

  // getSchedule() {
  //   console.log(this.selectedUser);
  //   let date = new Date();
  //   let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  //   let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  //   let startDate = firstDay.getFullYear()+"-"+(firstDay.getMonth()+1)+"-"+firstDay.getDate();
  //   let endDate = lastDay.getFullYear()+"-"+(lastDay.getMonth()+1)+"-"+lastDay.getDate();
  //   console.log(startDate);
  //   console.log(endDate);
  //   this._httpRequest.get('/api/calendar/user/'+ this.selectedUser.userId+'/'+ startDate +'/' + endDate).subscribe((resp: any) => {
  //     console.log(resp)
  //     this.userEvents = resp;
  //     this.showAddEventButton = true;
  //     this.processData();
  //   }, err => {
  //   });
  // }

  public onDateChange(args: DateChangeEvent): void {
   console.log(args);
   let startDay = new Date(args.dateRange.start);
   let startDate = args.dateRange.start.getFullYear()+"-"+(args.dateRange.start.getMonth()+1)+"-"+args.dateRange.start.getDate();
   let endDate = args.dateRange.end.getFullYear()+"-"+(args.dateRange.end.getMonth()+1)+"-"+(args.dateRange.end.getDate()-1);
    console.log(startDate);
    console.log(endDate);
    this._httpRequest.get('/api/calendar/user/'+ this.selectedUser.userId+'/'+ startDate +'/' + endDate).subscribe((resp: any) => {
      console.log(resp)
      this.userEvents = resp;
      this.processData();
    }, err => {
    });
  }

  showAddEventPopup() {
    const dialogRef = this.dialog.open(AddEventComponent, {
      width: '40em',
      height: '55em',
      data: {data: this.selectedUser }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    //   this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    //       let redirectTo = this.currentUrl;
    //       this.router.navigate([redirectTo]);
    // });
    });
  }

  test(event, eventStatus) {
    console.log(event);
    console.log(eventStatus);
    let dataToSend: any;
    if(eventStatus == 'save') {
      console.log(this.status);
      let startTime = new Date(event.formGroup.value.start);
      let endTime = new Date(event.formGroup.value.end);
      console.log(startTime)
      console.log(endTime)
      dataToSend = {
        userId: this.selectedUser.userId,
        title: event.formGroup.value.title,
        startTime: this.formatDateTime(startTime),
        endTime: this.formatDateTime(endTime),
        calendarEventType: this.eventType.value,
        repeatInterval: 0,
        repeatYear: 0,
        repeatMonth: 0,
        repeatDay: 0,
        repeatWeek: 0,
        repeatWeekDay: 0
      }
        console.log(dataToSend)
        if(this.status == 'add') {
          console.log('add');
          this._httpRequest.post(dataToSend, '/api/user/calendar/event').subscribe((resp: any) => {
            console.log(resp)
          }, err => {
          });
        } else if(this.status == 'edit') {
          console.log('edit');
          dataToSend['id'] = event.dataItem.id;
            this._httpRequest.edit(dataToSend, '/api/user/calendar/event').subscribe((resp: any) => {
              console.log(resp)
            }, err => {
            });
        }
      
    } else {
      this.status = eventStatus;
      console.log(this.status)
    }
  }

  formatDateTime(date) {
    const now = date;
    let dateTimeformat: any = '';
    let time: any;
    time = "T" + ("0" + now.getHours()).slice(-2) + ":" + ("0" + now.getMinutes()).slice(-2) + ":" + ("0" + now.getSeconds()).slice(-2);
    dateTimeformat = now.getFullYear() + "-" + ("0" + (now.getMonth() + 1)).slice(-2) + "-" + ("0" + now.getDate()).slice(-2) + time;
    return dateTimeformat
  }

  getEventSchedule(e) {
    console.log(e);
    console.log(this.selectedUser);
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let startDate = firstDay.getFullYear()+"-"+(firstDay.getMonth()+1)+"-"+firstDay.getDate();
    let endDate = lastDay.getFullYear()+"-"+(lastDay.getMonth()+1)+"-"+lastDay.getDate();
    console.log(startDate);
    console.log(endDate);
    this._httpRequest.get('/api/calendar/user/'+ this.selectedUser.userId+'/'+ startDate +'/' + endDate).subscribe((resp: any) => {
      console.log(resp)
      this.userEvents = resp;
      this.showAddEventButton = true;
      this.processData();
    }, err => {
    });
  }
}
