import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Services
import { HttpRequestsService } from './../../services/http-requests.service';
import { NavComponentsService } from './../../services/nav-components.service';
import { DataShareService } from './../../services/data-share.service';




  declare const $: any;
  declare interface RouteInfo {
    Icon: string;
    Id: string;
    IsArchived: string;
    Name: string;
    Parent: string;
    ParentId: string;
    Slug: string;
    Url: string;
  }

  export const ROUTES: RouteInfo[] = [];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  jobItems: any[];
  settingsItems: any[];
  ROUTES: RouteInfo[];
  currentRoute = '';
  
  displaySettings = false;
  displayJobs = false;

  data_userProfilescope: any = {};
  data_usermenu: any = {};
  userRole: any;
  constructor(
    protected _router: Router,
    protected _httpRequest: HttpRequestsService,
    public _navcomponents: NavComponentsService,
    public _datasShare: DataShareService,
    
  ) {
   
  }

  ngOnInit() {
    if(localStorage.getItem('UserProfile') && localStorage.getItem('UserId') && localStorage.getItem('UserMenus')) {
      let menu: any = localStorage.getItem('UserMenus');
      this.processMenuPlacement(JSON.parse(menu));
      // this.getUserMenu();
    } else {
      // this.getUserProfile();
    }
    this.currentRoute = this._router.url.substring(11);
    // this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.getUserSettings();
    this.userRole = JSON.parse(localStorage.getItem('UserRole'));
    // this.redirectToDashboard();
  }

  getUserProfile() {
    // this._httpRequest.get('/api/accounts/profile/')
    this._httpRequest.get('/api/user/profile')
    .subscribe(ret => {
      console.log(ret);
      this.data_userProfilescope = ret;
      localStorage.setItem('UserProfile', JSON.stringify(this.data_userProfilescope));
      localStorage.setItem('UserId', this.data_userProfilescope.id);
      this._datasShare.setSelectedUser(this.data_userProfilescope);
      this.getUserMenu();
    });
  }

  getUserMenu() {
    // this._httpRequest.get('/api/menus/' + this.data_userProfilescope.Id)
    this._httpRequest.get('/api/menu/')
    // this._httpRequest.get('/api/user/menu/')
    .subscribe(ret => {
      this.data_usermenu = ret;
      this.data_usermenu.push(
        {
          icon: "search",
          id: 21,
          isArchived: false,
          name: "Search",
          parent: null,
          parentId: null,
          slug: null,
          url: "my-jobs-grid",
        }, {
          icon: "business_center",
          id: 22,
          isArchived: false,
          name: "Raise Job",
          parent: null,
          parentId: null,
          slug: null,
          url: "raise-job",
        }
      )
      this.ROUTES = this.data_usermenu;
      console.log(this.ROUTES);
      this.menuItems = this.ROUTES.filter(menuItem => menuItem);
      localStorage.setItem('UserMenus', JSON.stringify(this.menuItems));
      this.processMenuPlacement(this.menuItems);
    });
  }

  processMenuPlacement(data) {
    console.log(data);
    let setting = [];
    let jobs = [];
    let menus = [];
    for(let x = 0; x < data.length; x++) {
      if(data[x].name == "Job Types") {
        setting.push(data[x]);
      } else if(data[x].name == "Manufacturers") {
        setting.push(data[x]);
      } else if(data[x].name == "Qualifications") {
        setting.push(data[x]);
      } else if(data[x].name == "Surveys") {
        setting.push(data[x]);
      } else if(data[x].name == "Sites") {
        setting.push(data[x]);
      } else if(data[x].name == "Equipment Type") {
        setting.push(data[x]);
      } else if(data[x].name == "Excel Templates") {
        setting.push(data[x]);
      } else if(data[x].name == "System Settings") {
        setting.push(data[x]);
      } else if(data[x].name == "My Jobs") {
        jobs.push(data[x]);
      } else if(data[x].name == "Search") {
        jobs.push(data[x]);
      } else if(data[x].name == "Raise Job") {
        jobs.push(data[x]);
      } else if(data[x].name == "Jobs") {
        jobs.push(data[x]);
      } else if(data[x].name == "Batch Job Assignment") {
        jobs.push(data[x]);
      } else if(data[x].name == "New Job") {
        jobs.unshift(data[x]);
      } else {
        menus.push(data[x]);
      }
    }
    this.settingsItems = setting.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    this.jobItems = jobs.sort((a,b) => (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0));
    this.menuItems = menus.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    console.log(this.settingsItems)
    console.log(this.jobItems)
    console.log(this.menuItems)
  }

  toggleSettings() {
    if(this.displaySettings == true) {
      this.displaySettings = false;
    } else {
      this.displaySettings = true;
    }
  }
  toggleJobs() {
    console.log(this.jobItems)
    if(this.displayJobs == true) {
      this.displayJobs = false;
    } else {
      this.displayJobs = true;
    }
  }

  routemenu(route) {
    this._router.navigate(['dashboard/' + route]);
    this.currentRoute = route;
  }

  logout(){
    console.log('logout')
    localStorage.clear();
    this._router.navigate(['login']);
  }

  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };

  redirectToDashboard() {
    // let role = JSON.parse(localStorage.getItem('UserRole'));
    // let proceed = true;
    // for(let x = 0; x < role.length; x++) {
    //   if(role[x] == 'Engineer') {
    //     proceed = false;
    //   } 
    // }
    // if(proceed == true) {
    //   this._router.navigate(['dashboard']);
    // }  else {
    //   this._router.navigate(['dashboard/']);
    // }
    this._router.navigate(['dashboard']);
  }
  getUserSettings() {
    this._httpRequest.get('/api/user/setting').subscribe((ret:any) => {
      localStorage.setItem('debugMode', ret[0].debugMode)
    })
  }
}
