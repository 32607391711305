import { Component, OnInit, ElementRef } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { Router } from '@angular/router';

@Component({
selector: 'app-navbar',
templateUrl: './navbar.component.html',
styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  private listTitles: any[];
  breadcrumbs: any;
  breadsize: any;
  location: Location;
    mobile_menu_visible: any = 0;
  private toggleButton: any;
  private sidebarVisible: boolean;
  userData: any;
  showSettingsFlag = false;

  constructor(location: Location,  private element: ElementRef, private router: Router) {
    this.location = location;
        this.sidebarVisible = false;
  }

  ngOnInit(){
      this.userData = JSON.parse(localStorage.getItem('UserProfile'));
      console.log(this.userData);
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
        var $layer: any = document.getElementsByClassName('close-layer')[0];
        if ($layer) {
          $layer.remove();
          this.mobile_menu_visible = 0;
        }
    });
    this.getUserRole();
  }

  sidebarOpen() {
      const toggleButton = this.toggleButton;
      const body = document.getElementsByTagName('body')[0];
      setTimeout(function(){
          toggleButton.classList.add('toggled');
      }, 500);

      body.classList.add('nav-open');

      this.sidebarVisible = true;
  };
  sidebarClose() {
      const body = document.getElementsByTagName('body')[0];
      this.toggleButton.classList.remove('toggled');
      this.sidebarVisible = false;
      body.classList.remove('nav-open');
  };
  sidebarToggle() {
      // const toggleButton = this.toggleButton;
      // const body = document.getElementsByTagName('body')[0];
      var $toggle = document.getElementsByClassName('navbar-toggler')[0];

      if (this.sidebarVisible === false) {
          this.sidebarOpen();
      } else {
          this.sidebarClose();
      }
      const body = document.getElementsByTagName('body')[0];

      if (this.mobile_menu_visible == 1) {
          // $('html').removeClass('nav-open');
          body.classList.remove('nav-open');
          if ($layer) {
              $layer.remove();
          }
          setTimeout(function() {
              $toggle.classList.remove('toggled');
          }, 400);

          this.mobile_menu_visible = 0;
      } else {
          setTimeout(function() {
              $toggle.classList.add('toggled');
          }, 430);

          var $layer = document.createElement('div');
          $layer.setAttribute('class', 'close-layer');


          if (body.querySelectorAll('.main-panel')) {
              document.getElementsByClassName('main-panel')[0].appendChild($layer);
          }else if (body.classList.contains('off-canvas-sidebar')) {
              document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
          }

          setTimeout(function() {
              $layer.classList.add('visible');
          }, 100);

          $layer.onclick = function() { //asign a function
            body.classList.remove('nav-open');
            this.mobile_menu_visible = 0;
            $layer.classList.remove('visible');
            setTimeout(function() {
                $layer.remove();
                $toggle.classList.remove('toggled');
            }, 400);
          }.bind(this);

          body.classList.add('nav-open');
          this.mobile_menu_visible = 1;
      }
  };

  getTitle(){
      var link = this.location.prepareExternalUrl(this.location.path());
      var titlee;
      const links = [];

      titlee = link.split('/');
      titlee.splice(0 , 1);
      for (let index = 0; index < titlee.length; index++) {
        const nameCapitalized = titlee[index].charAt(0).toUpperCase() + titlee[index].slice(1)
        links.push({'title': nameCapitalized, 'url': titlee[index]})
      }
      this.breadcrumbs = links;
      this.breadsize = links.length - 1;
      return links;
  }
  breadRoutes (x) {
      let tmp = '';
      for (let index = 0; index < x; index++) {
          tmp += this.breadcrumbs[index].url;
      }
      this.router.navigate([tmp]);
  }

  getUserRole() {
      console.log(localStorage.getItem('UserRole'))
      let roles = JSON.parse(localStorage.getItem('UserRole'))
      for(let x = 0; x < roles.length; x++) {
          if(roles[x] == 'Manager' || roles[x] == 'SuperAdmin') {
              this.showSettingsFlag = true;
          } else if(roles[x] == 'Engineer') {
              this.showSettingsFlag = false;
          }
      }
  }

  logout(){
    console.log('logout')
    localStorage.clear();
    this.router.navigate(['login']);
}

}
