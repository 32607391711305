import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';

import { catchError, retry, tap } from 'rxjs/operators';
import { HeaderConfigService } from './header-config.service';
import Swal from 'sweetalert2';
import { UserModel } from 'app/models/user.model';

export interface LoginData {
    'access_token': string ,
    'refresh_token': string,
    'expires_in': number,
    'userName': string,
    'Id': string,
}

@Injectable()
export class HttpRequestsService extends HeaderConfigService {
  headers = new HttpHeaders();
  constructor (
    private http: HttpClient,
    private router: Router
  ) { super(); }

  isLogin(): boolean {
    const access_token = localStorage.getItem('access_token');
    const expires_in = localStorage.getItem('expires_in');
    if (access_token && expires_in) {
    this.checkTokenExpiration();
      return true;
    }
    return false;
  }

  getAccessToken() {
    return localStorage.getItem('access_token');
  }

  getToken (data, endpoint) {
    const httpParams =  new HttpParams()
    .append('userName', data.userName)
    .append('clientId', data.clientId)
    .append('password', data.password)
    .append('clientSecret', '')
    .append('rememberMe', data.rememberMe);
    let dataToSend = {
      userName: data.userName,
      clientId: data.clientId,
      password: data.password,
      clientSecret: '',
      rememberMe: true
    }

    return this.http.post<LoginData>(this.api + endpoint, dataToSend, this._loginHeader()).pipe(
    tap ((response:any) => {
      console.log(response);
       localStorage.setItem('access_token', response.accessToken);
       localStorage.setItem('refresh_token', response.refreshToken);
      //  localStorage.setItem('userName', response.userName);
       localStorage.setItem('expires_in', response['.expiresIn']);
      if (localStorage.getItem('access_token') == null || localStorage.getItem('access_token') === '') {
        this.router.navigate(['/login']);
      }
       return response;
      } ),
    catchError((err: any) => {
      return err
    }));
  }

  checkTokenExpiration() {
    const expired_in = localStorage.getItem('expires_in');
    const expires_in = new Date(expired_in).getTime();
    if ( expires_in <= Date.now()  ) {
      console.log('check');
      this.refreshToken('/token');
    }
  }

  refreshTokenNew() {
    console.log('in');
    let dataToSend = {
      "clientId": "GPFocusAngular",
      "refreshToken": localStorage.getItem('refresh_token')
    }
    this.http.post('https://api.bcas.mfit.uk/api/Login/refreshToken',  dataToSend, this._loginHeader()).subscribe((ret:any) => {
      localStorage.setItem('access_token', ret.accessToken);
       localStorage.setItem('refresh_token', ret.refreshToken);
       localStorage.setItem('expires_in', ret['.expiresIn']);
    }), (err: any) => {
      console.log(err);
      this.router.navigate(['/login']);
      return err;
    }
  }

  refreshToken (endpoint) {
    const httpParams =  new HttpParams()
    .append('refresh_token', localStorage.getItem('refresh_token'))
    .append('grant_type', 'refresh_token')
    .append('access-control-allow-origin', '*')
    .append('client_id', 'GPFocusAngular');

    this.http.post(this.api + endpoint,  httpParams, this._loginHeader()).subscribe(ret => {
      const expiredin: number = Date.now() + (ret['expires_in'] * 1000);
          localStorage.setItem('access_token', ret['access_token']);
          localStorage.setItem('refresh_token', ret['refresh_token']);
          localStorage.setItem('expires_in', ret['.expires']);
          if (localStorage.getItem('token') === '') {
            localStorage.clear();
          this.router.navigate(['/login']);
          }
          window.location.reload();
    }), (err: any) => {
      console.log(err);
      this.router.navigate(['/login']);
      return err;
    }

  }
  get (endpoint) {
    return this.http.get(this.api + endpoint, this._tokenizedHeader()).pipe(
      tap (response => {
        return response;
      }),
    catchError((err: any) => {
      this.checkTokenExpiration();
      // return err
      return this.http.get(this.api + endpoint, this._tokenizedHeader()).pipe(
        tap (response => {
          return response;
        }),
      catchError((err: any) => {
        this.checkTokenExpiration();
        return err
      }));
    }));
  }

  post(data, endpoint ) {
    this.checkTokenExpiration();
    return this.http.post(this.api + endpoint, data,  this._tokenizedHeader()).pipe(
      tap (response => {
      console.log('post' + endpoint, response);
      return response;
    }),
    catchError((err: any) => {
      console.log(err);
      if(endpoint == '/api/accounts/create') {
        if(err.error.ModelState.Error.length == 1) {
          Swal.fire(
            'Error!',
            err.error.ModelState.Error[0],
            'error'
          )
        } else if(err.error.ModelState.Error.length > 1) {
          Swal.fire(
            'Error!',
            'Email or Username already exists',
            'error'
          )
        }
        
      } else {
        Swal.fire(
          'Cancelled',
          'Something went wrong. Please try resubmitting again, if problem persists please contact our support team.',
          'error'
        )
      }
      return err
    }));
  }

  delete(endpoint) {
    this.checkTokenExpiration();
    return this.http.delete(this.api + endpoint,  this._tokenizedHeader() ).pipe(
      tap (response => {
      return response;
    }),
    catchError((err: any) => {
      Swal.fire(
        'Cancelled',
        'Error! ' + err.ModelState[0],
        'error'
      )
      return err
    }));
  }

  edit( data, endpoint) {
    this.checkTokenExpiration();
    return this.http.put(this.api + endpoint, data, this._tokenizedHeader()).pipe(
      tap (response => {
      console.log( 'edit' + endpoint, response);
      return response;
    }),
    catchError((err: any) => {
      console.log(err);
      Swal.fire(
        'Cancelled',
        'Something went wrong. Please try resubmitting again, if problem persists please contact our support team.',
        'error'
      )
      return err
    }));
  }

  getRefreshToken(token: string) {
    console.log(token)
    let dataToSend = {
      "clientId": "GPFocusAngular",
      "refreshToken": token
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.post<UserModel>('https://api.bcas.mfit.uk/api/Login/refreshToken', dataToSend, {
      headers: httpHeaders,
    });
  }
}
