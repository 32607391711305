import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgxUiLoaderService } from "ngx-ui-loader";

// Services
import { NavComponentsService } from './../services/nav-components.service';
import { HttpRequestsService } from './../services/http-requests.service';
import { DataShareService } from './../services/data-share.service';

import Swal from 'sweetalert2';

declare const $: any;
declare interface RouteInfo {
  Icon: string;
  Id: string;
  IsArchived: string;
  Name: string;
  Parent: string;
  ParentId: string;
  Slug: string;
  Url: string;
}

export const ROUTES: RouteInfo[] = [];

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
  })
  export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  loginStatus = false;
  loadstatus = false;
  credserror = false;
  userRole: any;

  data_userProfilescope: any = {};
  data_usermenu: any = {};

  menuItems: any[];
  ROUTES: RouteInfo[];

  constructor(
    protected _router: Router,
    private _formBuilder: FormBuilder,
    protected _httpRequest: HttpRequestsService,
    public _datasShare: DataShareService,
    public _navcomponents: NavComponentsService,
    private ngxLoader: NgxUiLoaderService,
    private http: HttpClient,
    private router: Router
  ) {
    this.initLoginForm();
  }

  ngOnInit() {
    this.isLoggedIn();
  }

  initLoginForm() {
    this.loginForm = this._formBuilder.group({
      userName: ['', [Validators.required, Validators.pattern(this.emailPattern)] ],
      password: ['', Validators.required],
      clientId: ['GPFocusAngular'],
      grant_type: ['password'],
      clientSecret: [''],
      rememberMe: true
      
    });
  }

  isLoggedIn() {
    if (localStorage.getItem('access_token')) {
      this._navcomponents.show();
      this._router.navigate(['dashboard']);
     }
  }

  loginUser() {
    this.loadstatus = true;
    const data = JSON.parse(JSON.stringify(this.loginForm.value));
    // this._httpRequest.getToken(data, '/token')
    console.log(data);
    this._httpRequest.getToken(data, '/api/Login')
    .subscribe(ret => {
      console.log(ret);
      this.credserror = false;
      this.getUserProfile();
    }, (err:any) => {
      this.credserror = true;
      this.loadstatus = false;
    });
  }

  login() {
    this.loadstatus = true;
    const data = JSON.parse(JSON.stringify(this.loginForm.value));
    this.http.post('https://api.bcas.mfit.uk/api/Login', data, this._loginHeader()).subscribe((ret:any) => {
      console.log(ret);
      localStorage.setItem('access_token', ret.accessToken);
      localStorage.setItem('refresh_token', ret.refreshToken);
      localStorage.setItem('expires_in', ret['.expiresIn']);
      if (localStorage.getItem('access_token') == null || localStorage.getItem('access_token') === '') {
        this.router.navigate(['/login']);
      }
      this.credserror = false;
      this.getUserProfile();
    }, error => {
      this.credserror = true;
      this.loadstatus = false;
      this.loginStatus = true;
    })
  }

  getUserProfile() {
    // this._httpRequest.get('/api/accounts/profile/')
    this._httpRequest.get('/api/user/profile')
    .subscribe((ret:any) => {
      this.data_userProfilescope = ret;
      localStorage.setItem('UserProfile', JSON.stringify(this.data_userProfilescope));
      localStorage.setItem('UserId', this.data_userProfilescope.id);
      this._datasShare.setSelectedUser(this.data_userProfilescope);
      this.getUserMenu(ret);
    });
  }

  getUserMenu(user) {
    // this._httpRequest.get('/api/menus/' + user.Id)
    this._httpRequest.get('/api/user/menu/')
    .subscribe(ret => {
      this.data_usermenu = ret;
      this.data_usermenu.push(
        {
          icon: "search",
          id: 21,
          isArchived: false,
          name: "Search",
          parent: null,
          parentId: null,
          slug: null,
          url: "my-jobs-grid",
        },
        {
          icon: "business_center",
          id: 22,
          isArchived: false,
          name: "Raise Job",
          parent: null,
          parentId: null,
          slug: null,
          url: "raise-job",
        }
      )
      this.ROUTES = this.data_usermenu;
      this.menuItems = this.ROUTES.filter(menuItem => menuItem);
      localStorage.setItem('UserMenus', JSON.stringify(this.menuItems));
      // this.processMenuPlacement(this.menuItems);
      this.getUserRole();
    });
  }

  getUserRole() {
    // if(localStorage.getItem('UserRole')) {
    //   console.log(localStorage.getItem('UserRole'));
    //   let ret = JSON.parse(localStorage.getItem('UserRole'));
    //   for(let x = 0; x < ret.length; x++) {
    //     if(ret[x] == 'SuperAdmin' && ret[x] == 'Admin') {
    //       this.userRole = 'SuperAdmin';
    //     } else if (ret[x] == 'Manager') {
    //         this.userRole = 'Manager'
    //     } else if(ret[x] == 'Engineer' || ret[x] == 'User') {
    //         this.userRole = 'Engineer'
    //         this._router.navigate(['dashboard/my-jobs']);
    //     }
    //   }
    // } else {
      this.ngxLoader.start();
      this._httpRequest.get('/api/user/role/')
      // this._httpRequest.get('/api/user/role/' + localStorage.getItem('UserId'))
      .subscribe((ret:any) => {
        console.log('in');
        this.ngxLoader.stop();
        if(ret.length == 0) {
          Swal.fire({
            title: 'Error',
            text: 'No roles associated with this user.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'Close',
          }).then((result) => {
            localStorage.clear();
            this._router.navigate(['login']);
          })
        } else {
          for(let x = 0; x < ret.length; x++) {
            if(ret[x] == 'SuperAdmin' || ret[x] == 'Admin') {
              this.userRole = 'SuperAdmin';
              localStorage.setItem('UserRole', JSON.stringify(ret));
              this._router.navigate(['dashboard']);
            } else if (ret[x] == 'Manager') {
                this.userRole = 'Manager'
                localStorage.setItem('UserRole', JSON.stringify(ret));
                this._router.navigate(['dashboard']);
            } else if(ret[x] == 'Engineer' || ret[x] == 'User') {
                this.userRole = 'Engineer'
                localStorage.setItem('UserRole', JSON.stringify(ret));
                this._router.navigate(['dashboard']);
            } else {
              
            }
          }
        }

      });
    // }
  }

  _loginHeader = (headers?: HttpHeaders | null): object => {
    headers = headers || new HttpHeaders();
    headers = headers.set('Accept', 'application/json, text/plain, */*');
    headers = headers.set('Access-Control-Allow-Origin', '*');
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Access-Control-Allow-Headers', 'Content-Type');
    return {
      headers: headers
    }
  }

}