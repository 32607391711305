import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileValidators } from 'ngx-file-drag-drop';
import { HttpRequestsService } from './../../../services/http-requests.service';
import Swal from 'sweetalert2';
import { State } from '@progress/kendo-data-query';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { SchedulerEvent } from "@progress/kendo-angular-scheduler";

@Component({
  selector: 'app-booked-job',
  templateUrl: './booked-job.component.html',
  styleUrls: ['./booked-job.component.css']
})

export class BookedJobDialogComponent {
  unselectedUser: any = [];
  selectedUser: any = [];
  selectedDefaultUser: any = [];

  selectableSettings: SelectableSettings = {
    checkboxOnly: true,
    mode: 'single',
    drag: false
  };

  public value: Date = new Date(Date.now());
  public format = "MM/dd/yyyy HH:mm";

  constructor(
    public dialogRef: MatDialogRef<BookedJobDialogComponent>,
    protected _httpRequest: HttpRequestsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    this.unselectedUser = this.data.user;
  }

  ngOnInit(): void {
    console.log('booked')
    console.log(this.data);
    if(this.data.type == 'rebook-single-job') {
      this.value = new Date(this.data.jobs.bookedDate);
    } else if(this.data.type == 'batch-reassign-book') {
      this.selectedDefaultUser = this.data.selectedUsers;
    }

    if(this.data.jobs.user) {
      this.selectedUser = this.data.jobs.user;
      this.selectedDefaultUser.push(this.selectedUser)
      this.removeTheDefaultUser();
    }
  }

  public onSelect(e) {
    this.selectedUser = e.selectedRows[0].dataItem;
    console.log(this.selectedUser);
}

public disabledDates = (date: Date): boolean => {
  return date < new Date();
}

removeTheDefaultUser() {
  console.log(this.unselectedUser);
  console.log(this.selectedUser);
  for(let x = 0; x < this.unselectedUser.length; x++) {
      if(this.selectedUser) {
          if(this.unselectedUser[x].id == this.selectedUser.id) {
              this.unselectedUser.splice(x, 1);
          }
      } else {

      }
      
  }
  console.log(this.unselectedUser);
}

rebookedJobs() {
  let dataToSend = {
    "bookedDate": this.formatDateTimeSelected()
  }
  console.log(this.selectedUser);
  console.log(this.data.jobs);
  console.log(dataToSend);
  this._httpRequest.edit(dataToSend, '/api/job/process/book/' + this.selectedUser.id + '/' + this.data.jobs.id).subscribe((ret:any) => {
    this.onNoClick();
  });
}

reassignAndBookJob() {
  let dataToSend = {
    "bookedDate": this.formatDateTimeSelected()
  }
  console.log(this.selectedUser);
  console.log(this.data.jobs);
  console.log(dataToSend);
  this._httpRequest.edit(dataToSend, '/api/job/process/assign/' + this.selectedUser.id + '/' + this.data.jobs.id).subscribe((ret:any) => {
    this.rebookedJobs();
  });
}

reassignAndBookBatchJob() {
  let dataToSend = [];
  for(let x = 0; x < this.data.jobs.length; x++) {
    dataToSend.push(this.data.jobs[x].id)
  }
  console.log(this.selectedUser.userName);
  console.log(dataToSend);
  this.rebookBatchJobs();
  this._httpRequest.edit(dataToSend, '/api/job/process/batch/assign/' + this.selectedUser.userName).subscribe((ret:any) => {
    this.rebookBatchJobs();
  });
}

rebookBatchJobs() {
  let jobs = [];
  for(let x = 0; x < this.data.jobs.length; x++) {
    jobs.push(this.data.jobs[x].id)
  }
  let dataToSend = {
    "jobs": jobs,
    "bookedDate": this.formatDateTimeSelected()
  }
  console.log(dataToSend)
  this._httpRequest.edit(dataToSend, '/api/job/process/batch/book').subscribe((ret:any) => {
    // location.reload();
    this.onNoClick();
    // Swal.fire(
    //     'Success!',
    //     'Assigned to user successfully!',
    //     'success'
    // ).then((result) => {
    //     location.reload();
    // })
  });
}

bookedJobs() {
  let dataToSend = {
    "bookedDate": this.formatDateTimeSelected()
  }

  this._httpRequest.edit(dataToSend, '/api/job/process/book/' + this.selectedUser.id + '/' + this.data.jobs.id).subscribe((ret:any) => {
    // location.reload();
    this.onNoClick();
    // Swal.fire(
    //     'Success!',
    //     'Assigned to user successfully!',
    //     'success'
    // ).then((result) => {
    //     location.reload();
    // })
  });
}

bookedBatchJobs() {
  let jobs = [];
    for (var x = 0 ; x < this.data.jobs.length; x++) {
      jobs.push(this.data.jobs[x].id);
    }
  let dataToSend = {
    "jobs": jobs,
    "bookedDate": this.formatDateTimeSelected(),
    // "userId": this.selectedUser.id
  }    
  console.log(dataToSend);
    this._httpRequest.edit(dataToSend, '/api/job/process/batch/book').subscribe((ret:any) => {
        // location.reload();
        this.onNoClick();
        // Swal.fire(
        //     'Success!',
        //     'Assigned to user successfully!',
        //     'success'
        // ).then((result) => {
        //     location.reload();
        // })
    });
}

formatDateTime() {
  const now = new Date();
  let dateTimeformat: any = '';
  dateTimeformat = now.getFullYear() + "-" + String(now.getMonth() + 1).padStart(2, '0') + "-" + String(now.getDate()) + "T" + String(String(now.getHours()).padStart(2, '0') + ":" + String(now.getMinutes()).padStart(2, '0') + ":" + String(now.getSeconds()).padStart(2, '0'));
  // console.log(dateTimeformat);
  return dateTimeformat
}

formatDateTimeSelected() {
  let now = this.value;
  let dateTimeformat: any = '';
  let day: any;
  let month: any;
  let time: any;
  if((now.getMonth() + 1) == 1 ||(now.getMonth() + 1) == 2 ||(now.getMonth() + 1) == 3 ||(now.getMonth() + 1) == 4 ||(now.getMonth() + 1) == 5 ||(now.getMonth() + 1) == 6 ||(now.getMonth() + 1) == 7 ||(now.getMonth() + 1) == 8 ||(now.getMonth() + 1) == 9) {
    month = "0" + (now.getMonth() + 1);
  } else {
    month = now.getMonth() + 1;
  }

  if(now.getDate() == 1 ||now.getDate() == 2 ||now.getDate() == 3 ||now.getDate() == 4 ||now.getDate() == 5 ||now.getDate() == 6 ||now.getDate() == 7 ||now.getDate() == 8 ||now.getDate() == 9){
    day = "0" + now.getDate();
  } else {
    day = now.getDate();
  }

  if((now.getSeconds() == 0 || now.getSeconds() == 1 || now.getSeconds() == 2 || now.getSeconds() == 3 || now.getSeconds() == 4 || now.getSeconds() == 5 || now.getSeconds() == 6 || now.getSeconds() == 7 || now.getSeconds() == 8 || now.getSeconds() == 9) && (now.getMinutes() == 0 || now.getMinutes() == 1 || now.getMinutes() == 2 || now.getMinutes() == 3 || now.getMinutes() == 4 || now.getMinutes() == 5 || now.getMinutes() == 6 || now.getMinutes() == 7 || now.getMinutes() == 8 || now.getMinutes() == 9) && (now.getHours() == 0 || now.getHours() == 1 || now.getHours() == 2 || now.getHours() == 3 || now.getHours() == 4 || now.getHours() == 5 || now.getHours() == 6 || now.getHours() == 7 || now.getHours() == 8 || now.getHours() == 9)) {
    time = "T" + "0" + now.getHours() + ":" + "0" + now.getMinutes() + ":" + "0" + now.getSeconds();
  } else if((now.getSeconds() == 0 || now.getSeconds() == 1 || now.getSeconds() == 2 || now.getSeconds() == 3 || now.getSeconds() == 4 || now.getSeconds() == 5 || now.getSeconds() == 6 || now.getSeconds() == 7 || now.getSeconds() == 8 || now.getSeconds() == 9) && (now.getMinutes() == 0 || now.getMinutes() == 1 || now.getMinutes() == 2 || now.getMinutes() == 3 || now.getMinutes() == 4 || now.getMinutes() == 5 || now.getMinutes() == 6 || now.getMinutes() == 7 || now.getMinutes() == 8 || now.getMinutes() == 9)) {
    time = "T" + now.getHours() + ":" + "0" + now.getMinutes() + ":" + "0" + now.getSeconds();
  } else if((now.getSeconds() == 0 || now.getSeconds() == 1 || now.getSeconds() == 2 || now.getSeconds() == 3 || now.getSeconds() == 4 || now.getSeconds() == 5 || now.getSeconds() == 6 || now.getSeconds() == 7 || now.getSeconds() == 8 || now.getSeconds() == 9) && (now.getHours() == 0 || now.getHours() == 1 || now.getHours() == 2 || now.getHours() == 3 || now.getHours() == 4 || now.getHours() == 5 || now.getHours() == 6 || now.getHours() == 7 || now.getHours() == 8 || now.getHours() == 9)) {
    time = "T" + "0" + now.getHours() + ":" + now.getMinutes() + ":" + "0" + now.getSeconds();
  } else if((now.getMinutes() == 0 || now.getMinutes() == 1 || now.getMinutes() == 2 || now.getMinutes() == 3 || now.getMinutes() == 4 || now.getMinutes() == 5 || now.getMinutes() == 6 || now.getMinutes() == 7 || now.getMinutes() == 8 || now.getMinutes() == 9) && (now.getHours() == 0 || now.getHours() == 1 || now.getHours() == 2 || now.getHours() == 3 || now.getHours() == 4 || now.getHours() == 5 || now.getHours() == 6 || now.getHours() == 7 || now.getHours() == 8 || now.getHours() == 9)) {
    time = "T" + "0" + now.getHours() + ":" + "0" + now.getMinutes() + ":" + now.getSeconds();
  } else if(now.getSeconds() == 0 || now.getSeconds() == 1 || now.getSeconds() == 2 || now.getSeconds() == 3 || now.getSeconds() == 4 || now.getSeconds() == 5 || now.getSeconds() == 6 || now.getSeconds() == 7 || now.getSeconds() == 8 || now.getSeconds() == 9) {
    time = "T" + now.getHours() + ":" + now.getMinutes() + ":" + "0" + now.getSeconds();
  } else if(now.getMinutes() == 0 || now.getMinutes() == 1 || now.getMinutes() == 2 || now.getMinutes() == 3 || now.getMinutes() == 4 || now.getMinutes() == 5 || now.getMinutes() == 6 || now.getMinutes() == 7 || now.getMinutes() == 8 || now.getMinutes() == 9) {
    time = "T" + now.getHours() + ":" + "0" + now.getMinutes() + ":" + now.getSeconds();
  } else if(now.getHours() == 0 || now.getHours() == 1 || now.getHours() == 2 || now.getHours() == 3 || now.getHours() == 4 || now.getHours() == 5 || now.getHours() == 6 || now.getHours() == 7 || now.getHours() == 8 || now.getHours() == 9) {
    time = "T" + "0"  + now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds();
  } else {
    time = "T" + now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds()
  }

  dateTimeformat = now.getFullYear() + "-" + month + "-" + day + time;
  // console.log(dateTimeformat);
  return dateTimeformat
}

onNoClick(): void {
  this.dialogRef.close();
}

assignAndBookJobs() {
  console.log('batch');
  let dataToSend = [];
    for (var x = 0 ; x < this.data.jobs.length; x++) {
    dataToSend.push(this.data.jobs[x].id);
    }
    console.log(dataToSend);
    console.log(this.selectedUser.userName);
    this._httpRequest.edit(dataToSend, '/api/job/process/batch/assign/' + this.selectedUser.userName + '/').subscribe((ret:any) => {
      this.bookedBatchJobs();
        // location.reload();
        // this.onNoClick();
        // Swal.fire(
        //     'Success!',
        //     'Assigned to user successfully!',
        //     'success'
        // ).then((result) => {
        //     location.reload();
        // })
    });
}

assignSingleAndBookJobs() {
  let dataToSend = {
    "bookedDate": this.formatDateTimeSelected()
  }
  console.log(dataToSend)
  this._httpRequest.edit('', '/api/job/process/assign/' + this.selectedUser.id + '/' + this.data.jobs[0].id).subscribe((ret: any) => {
    this._httpRequest.edit(JSON.stringify(dataToSend) + '', '/api/job/process/book/' + this.selectedUser.id + '/' + this.data.jobs[0].id).subscribe((ret: any) => {
      this.onNoClick();
    })
  })
}


}
