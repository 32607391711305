import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class HeaderConfigService {

  // api = 'https://aidental.api.mfit.uk';
  api = 'https://api.bcas.mfit.uk';
  // api = 'https://aidental.api.mfitonline.co.uk';


  constructor() { }

  _publicHeader = (headers?: HttpHeaders | null): object => {
    headers = headers || new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Accept', 'application/json, text/plain, */*');
    return {
      headers: headers
    }
  }

  _loginHeader = (headers?: HttpHeaders | null): object => {
    headers = headers || new HttpHeaders();
      // .append('Content-Type', 'application/json')
      // .append('Access-Control-Allow-Headers', 'Content-Type')
      // .append('Access-Control-Allow-Methods', 'GET')
      // .append('Access-Control-Allow-Origin', '*');
    // headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Accept', 'application/json, text/plain, */*');
    headers = headers.set('Access-Control-Allow-Origin', '*');
    headers = headers.set('Content-Type', 'application/json');
    // headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    headers = headers.set('Access-Control-Allow-Headers', 'Content-Type');
    // headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');

    return {
      headers: headers
    }
  }

  _tokenizedHeader = (headers?: HttpHeaders | null): object => {
    headers = headers || new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    headers = headers.append('Accept', 'application/json');
    return {
      headers: headers
    };
  }
}
