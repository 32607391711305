import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

import { ConnectionService } from 'ng-connection-service'; 
import Swal from 'sweetalert2';
import { HttpRequestsService } from './../../../services/http-requests.service';

@Component({
  selector: 'dialog-job-summary-dialog',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']

})


export class DialogJobSummaryDialog implements OnInit {
  status = 'ONLINE';
  isConnected = true;
  surveyAnswers;
  qtd = [];

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogJobSummaryDialog>,
    private connectionService: ConnectionService,
    private _httpRequest: HttpRequestsService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data);
    }

    ngOnInit() {
      this.connectionService.monitor().subscribe(isConnected => {
        console.log(isConnected);
        this.isConnected = isConnected;
        if (this.isConnected) {
          this.status = "ONLINE";
        }
        else {
          this.status = "OFFLINE";
        }
      })
      console.log(this.status);
      this.getSurveyAnswers();
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

    getSurveyAnswers() {
      if(localStorage.getItem('surveyAnswers')) {
        this.surveyAnswers = JSON.parse(localStorage.getItem('surveyAnswers'));
        console.log(this.surveyAnswers);
        for(let x = 0; x < this.surveyAnswers.length; x++) {
          this.qtd[this.surveyAnswers[x].QuestionId] = this.surveyAnswers[x].Answer;
        }
      }
    }

    submitAnswers() {
      if(this.status == "ONLINE") {
        let endpoint = '/api/answer/' + this.data.survey.jobData.Id + '/' + this.data.survey.jobSurvey.Id;
        let dataToSend = {
          endpoint: endpoint,
          data: this.surveyAnswers
        };
          this._httpRequest.post(dataToSend, endpoint).subscribe((ret:any) => {
            Swal.fire({
              title: 'Success',
              text: 'Answers has been added to the job',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Close',
            }).then((result) => {
              this.dialogRef.close('Answers Submitted');
            })
          });
      } else {
        let apiQueue;
        if(localStorage.getItem('APIQueue')) {
          apiQueue = JSON.parse(localStorage.getItem('APIQueue'));
          let endpoint = '/api/answer/' + this.data.survey.jobData.Id + '/' + this.data.survey.jobSurvey.Id;
          let dataToStore = {
            endpoint: endpoint,
            data: this.surveyAnswers
          };
          apiQueue.push(dataToStore);
          localStorage.setItem('APIQueue', JSON.stringify(apiQueue));
        } else {
          let endpoint = '/api/answer/' + this.data.survey.jobData.Id + '/' + this.data.survey.jobSurvey.Id;
          let dataToStore = {
            endpoint: endpoint,
            data: this.surveyAnswers
          };
          localStorage.setItem('APIQueue', JSON.stringify([dataToStore]));
        }
        Swal.fire({
          title: 'Success',
          text: 'Added the submit process to queue',
          icon: 'success',
        }).then((result)=> {
          localStorage.setItem('surveyAnswers', JSON.stringify([]));
          this.dialogRef.close('Pushed to Queue');
        })
      }
      

      // let endpoint = '/api/answer/' + this.data.survey.jobData.Id + '/' + this.data.survey.jobSurvey.Id;
      // let dataToStore = {
      //   endpoint: endpoint,
      //   data: this.surveyAnswers
      // };
      // let apiQueue = [dataToStore];
      // console.log(apiQueue);
      // localStorage.setItem('APIQueue', JSON.stringify(apiQueue));
      // console.log(this.data.survey.survey.Id);
      // this.dialogRef.close('Submitted');
      // this._httpRequest.post(this.surveyAnswers.answers, '/api/answer/'+ this.surveyAnswers.jobId + '/' + this.data.survey.survey.Id)
      //   .subscribe(ret => {
      //     console.log(ret);
      //     Swal({
      //       title: 'Success',
      //       text: 'Succesfully added the answers to the job',
      //       type: 'success',
      //       // showCancelButton: true,
      //       // confirmButtonText: 'Yes!',
      //       // cancelButtonText: 'Not now'
      //     }).then((result) => {
      //       // this.submitAnswersFlag = false;
      //       // if(this.step == this.data.survey.Sections.length - 1) {
      //       //   this.onNoClick(0);
      //       // } else {
      //       //   this.nextStep();
      //       // }
      //     })
      //   });
  
    }
}