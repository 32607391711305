import { Component, OnInit, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SignaturePad } from 'angular2-signaturepad';
import { empty } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpRequestsService } from 'app/services/http-requests.service';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';


@Component({
  selector: 'app-survey-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})

export class JobSurveyPreviewDialogComponent {

  qtd: any;
  dataToPass: any;
  public current = 0;
  public currentSurveyStep = 0;
  public steps: any = [];
  surveySteps: any = [];
  surveyStepsLength = 0;
  public userTestEquipments: GridDataResult;
  userTestEquipment = [];
  public stateUserTestEquipment: State = {
    skip: 0,
    // take: 5,
  
    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [{ field: 'Id', operator: 'contains', value: '' }]
    }
  };

  @ViewChild('surveySignaturePad') surveySignaturePad: SignaturePad;
  signaturePadOptions: Object = {
    'minWidth': 5,
    'canvasWidth': 1090,
    'canvasHeight': 300
  };
  signaturePadSurveyWidth: Object = {
    'minWidth': 5,
    'canvasWidth': 950,
    'canvasHeight': 300
  };

  constructor(
    public dialogRef: MatDialogRef<JobSurveyPreviewDialogComponent>,
    private http: HttpClient,
    protected _httpRequest: HttpRequestsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit(): void {
    console.log(this.data);
    this.processAnswers();
    // this.qtd = this.data.questions.answers;
    console.log(this.qtd);
    this.dataToPass = {
      data: this.data,
      type: 'preview'
    }
    this.getSectionSteps();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  download(x, index) {
    console.log(index);
    console.log(this.qtd[x]);
    const linkSource = 'data:' + this.qtd[x].Attachments[index].MimeType + ';base64,' + this.qtd[x].Attachments[index].File;
    console.log(linkSource);
    const downloadLink = document.createElement("a");
    const fileName = "attachment" + this.qtd[x].Attachments[index].Type;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  getSectionSteps() {
    this.getTestEquipment();
    console.log(this.data.questions.data);
    let testSteps = [];
    for(let x = 0; x <= this.data.questions.data.length; x++) {
      console.log(this.data.questions.data[x]);
        // console.log(x, this.data.questions.data[x].index);
        // testSteps[this.data.questions.data[x].index] = {
        //   label: this.data.questions.data[x].description,
        //   data: this.data.questions.data[x],
        //   disabled: false 
        // }
        // if(x == this.data.questions.data.length) {
        //   let data = {
        //     'description': 'Test Equipment',
        //     'questions': []
        //   };
        //   data['questions'] = [];
        //   data.questions.push(
        //     {
        //       'questionTypeId': 'TestEquipment',
        //       'options': []
        //     }
        //   );
        //   let testEquipment = {
        //     label: 'Test Equipment',
        //     data: {},
        //     disabled: false
        //   };
        //   console.log(data);
        //   testEquipment['data'] = data;
        //   console.log(testEquipment);
        //   testSteps[x] = testSteps[x] || [];
        //   testSteps[x].push(testEquipment);
        // } else {
        //   testSteps[this.data.questions.data[x].index] = {
        //     label: this.data.questions.data[x].description,
        //     data: this.data.questions.data[x],
        //     disabled: false
        //   }
        // }
        if(this.data.questions.data[x]) {
          testSteps[this.data.questions.data[x].index] = {
            label: this.data.questions.data[x].description,
            data: this.data.questions.data[x],
            disabled: false
          }
        }
    }
    console.log(testSteps)
    // testSteps.push({
    //   label: 'Test Equipment',
    //   data: {
    //     'description': 'Test Equipment',
    //     'questions': []
    //   },
    //   disabled: false
    // }) 
    // testSteps[testSteps.length - 1].data.questions.push(
    //   {
    //     'questionTypeId': 'TestEquipment',
    //     'options': []
    //   }
    // )
    let newArr = [];
    for(let x = 0; x < testSteps.length; x++) {
      if(testSteps[x]) {
        newArr.push(testSteps[x]);
      }
    }
    this.surveySteps = newArr;
    this.surveyStepsLength = this.surveySteps.length;
    console.log(this.surveySteps);
    let questionHandler = [];
    for(let x = 0; x < this.surveySteps.length; x++) {
      questionHandler = [];
      for(let y = 0; y < this.surveySteps[x].data.questions.length; y++) {
        console.log(this.surveySteps[x].data.questions[y]);
        if(questionHandler[this.surveySteps[x].data.questions[y].index] != null) {
          questionHandler[this.surveySteps[x].data.questions[y].index + 1] = this.surveySteps[x].data.questions[y];
        } else {
          questionHandler[this.surveySteps[x].data.questions[y].index] = this.surveySteps[x].data.questions[y];
        }
      }
      this.surveySteps[x].data.questions = questionHandler;
    }
    this.manipulateQuestions();
  }

  manipulateQuestions() {
    for(let x = 0; x < this.surveySteps.length; x++) {
      for(let y = 0; y < this.surveySteps[x].data.questions.length; y++) {
        this.surveySteps[x].data.questions[y].options = this.reArrangeDisplayOrder(this.surveySteps[x].data.questions[y]);
      }
    }
    
    this.surveySteps.push({
      label: 'Test Equipment',
      data: {
        'description': 'Test Equipment',
        'questions': [
          {
            'questionTypeId': 'TestEquipment',
            'options': []
          }
        ]
      },
      disabled: false
    })
    console.log(this.surveySteps)
  }

  reArrangeDisplayOrder(questions: any) {
    let arrayHandler = [];
    console.log(questions)
    for(let x = 0; x < questions.options.length; x++) {
      arrayHandler.splice(questions.options[x].displayOrder, 0, questions.options[x])
    }
    return arrayHandler
  }

  processAnswers() {
    console.log('in')
    let tempAnswers = [];
    for(let x = 0; x < this.data.questions.answers.length; x++) {
      console.log(this.data.questions.answers[x].value)
      if(this.data.questions.answers[x].value) {
        tempAnswers[this.data.questions.answers[x].questionId] = this.data.questions.answers[x].value
      } else {
        tempAnswers[this.data.questions.answers[x].questionId] = this.data.questions.answers[x].attachments[0].file
      }
      // tempAnswers.splice(this.data.questions.answers[x].questionId, 0, this.data.questions.answers[x])
    }
    this.qtd = tempAnswers;
    console.log(this.qtd);
  }

  proceedCurrentSurveyStep() {
      // this.surveySignaturePad.off();
      this.current += 1;
      this.currentSurveyStep += 1;
      console.log(this.surveySteps[this.currentSurveyStep]);
      console.log(this.surveyStepsLength);
  }

  previousCurrentSurveyStep() {
    this.current -= 1;
    this.currentSurveyStep -= 1;
    console.log(this.surveySteps[this.currentSurveyStep]);
  }

  downloadPDF() {
    console.log(JSON.stringify(this.data.data))
    this.downloadFilePDF(this.data.data)
    .subscribe(data => this.downloadFileDataPDF(data, this.data.data))
    ,error => console.log("Error downloading the file."), () => console.log("Error");
  }

  downloadExcel() {
    this.downloadFile(this.data.data).subscribe(data => this.downloadFileExcel(data)),error => console.log("Error downloading the file."), () => console.log("Error");
  }

  downloadFilePDF(data: any){
    let sUrl:string = "https://api.bcas.mfit.uk/api/excel/job/pdf/" + data.id;
    let headers = new HttpHeaders({
        'Authorization':'Bearer ' + localStorage.getItem('access_token')
    })
    return this.http.get(sUrl, { headers:headers, responseType:"blob"});
  }

  downloadFileDataPDF(data: any, job:any){
    const d = new Date();
    let name: any;
    if(job.equipment && job.equipment) {
      name = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + job.equipment.assetNumber + '-' + job.equipment.site.address1 + '.pdf';
    } else {
      name = 'Job Details.pdf'
    }
    var url = window.URL.createObjectURL(new Blob([data]));
    var a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  downloadFile(data: any){
    let sUrl:string = "https://api.bcas.mfit.uk/api/excel/job/" + data.id;
    let headers = new HttpHeaders({
        'Authorization':'Bearer ' + localStorage.getItem('access_token')
    })
    return this.http.get(sUrl, { headers:headers, responseType:"blob"});
}

downloadFileExcel(data: any){
    let name:any;
    const d = new Date();
    if(this.data.data.equipment) {
        name = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + this.data.data.equipment.assetNumber + '-' + this.data.data.equipment.site.name + '.xlsx'
    } else {
        name = 'Result.xlsx'
    }
    var url = window.URL.createObjectURL(new Blob([data]));
    var a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  getYearMonthName(date:string) {
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    let newDate = date.substring(0,4);
    let month = date.substring(5,7)
    console.log(newDate);
    console.log(month);
    let dateF: any;
    if(month == '01') {
      dateF = newDate + '-' + months[0];
    } else if(month == '02') {
      dateF = newDate + '-' + months[1];
    } else if(month == '03') {
      dateF = newDate + '-' + months[2];
    } else if(month == '04') {
      dateF = newDate + '-' + months[3];
    } else if(month == '05') {
      dateF = newDate + '-' + months[4];
    } else if(month == '06') {
      dateF = newDate + '-' + months[5];
    } else if(month == '07') {
      dateF = newDate + '-' + months[6];
    } else if(month == '08') {
      dateF = newDate + '-' + months[7];
    } else if(month == '09') {
      dateF = newDate + '-' + months[8];
    } else if(month == '10') {
      dateF = newDate + '-' + months[9];
    } else if(month == '11') {
      dateF = newDate + '-' + months[10];
    } else if(month == '12') {
      dateF = newDate + '-' + months[11];
    }
    console.log(dateF)
    return dateF;
  }

  currentStepChange(data) {
    // this.current += data;
    this.currentSurveyStep = data;
  }

  getTestEquipment() {
    console.log(this.data)
    this._httpRequest.get('/api/job/testequipment/' + this.data.job.id).subscribe((ret: any) => {
      console.log(ret);
      this.userTestEquipment = ret;
      this.userTestEquipments = process(ret, this.stateUserTestEquipment);
      console.log(this.userTestEquipments)
    })
  }


}